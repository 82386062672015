
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/secret-key"
export default function () {
    this.create = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-secret-key`, data).then(res => res.data);
    }
    this.update = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-secret-key`, data).then(res => res.data);
    }
    this.delete = (data) => {
        return axios.post(`${APP_IP}/${base_path}/delete-secret-key`, data).then(res => res.data);
    }
    this.get_all = (id) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-secret-key/${id}`).then(res => res.data);
    }
}
