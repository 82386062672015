class BE_Token {
    constructor(type, value, line, position) {
        this.type = type;
        this.value = value;
        this.line = line;
        this.position = position;
    }
}

class BE_Token_TYPE {
    static L_PAR = "L_PAR";
    static R_PAR = "R_PAR";
    static L_SQR = "L_SQR";
    static R_SQR = "R_SQR";
    static DOT = "DOT";
    static COMMA = "COMMA";
    static NUMBER = "NUMBER";
    static STRING = "STRING";
    static VARIABLE = "VARIABLE";
    static TRUE = "TRUE";
    static FALSE = "FALSE";
    static NULL = "NULL";
    static AND = "AND";
    static OR = "OR";
    static NOT = "NOT";
    static FOREACH = "FOREACH";
    static FUNCTION = "FUNCTION";
    static IF = "IF";
    static ELSE = "ELSE";
    static LOOP = "LOOP";
    static EACH = "EACH";
    static IN = "IN";
    static LOOP_TIMES = "LOOP_TIMES";
    static END = "END";
    static BREAK = "BREAK";
    static CONTINUES = "CONTINUES";
    static GLOBAL = "GLOBAL";
    static PLUS = "PLUS";
    static MINUS = "MINUS";
    static DIVIDE = "DIVIDE";
    static MULTIPY = "MULTIPY";
    static MOD = "MOD";
    static EQUAL = "EQUAL";
    static EQUAL_EQAUL = "EQUAL_EQAUL";
    static NOT_EQUAL = "NOT_EQUAL";
    static LESS_THAN_EQUAL = "LESS_THAN_EQUAL";
    static LESS_THAN = "LESS_THAN";
    static GREATER_THAN_EQUAL = "GREATER_THAN_EQUAL";
    static GREATER_THAN = "GREATER_THAN";
    static RETURN = "RETURN";
    static EOF = "EOF";
}

export default class BE_Lexer {
    constructor(text) {
        this.text = text;
        this.char_pos = 0;
        this.line = 1;
        this.position_trace = 0;
        this.char = "";
        this.tokens = [];
        this.errors = [];
    }

    make_error(message, info = null) {
        if (info === null) {
            this.errors.push(`(${this.line},${this.position_trace}) : ${message}`);
        } else {
            this.errors.push(`(${this.line},${this.position_trace}) : ${message} -> '${info}'`);
        }
    }

    next() {
        this.char_pos++;
        this.position_trace++;
    }

    current_char() {
        if (this.char_pos < this.text.length) {
            return this.text[this.char_pos];
        } else {
            return null;
        }
    }

    current_look_ahead() {
        if (this.char_pos + 1 < this.text.length) {
            return this.text[this.char_pos + 1];
        } else {
            return null;
        }
    }

    endsWith(string, suffix) {
        const stringLength = string.length;
        const suffixLength = suffix.length;
        if (suffixLength > stringLength) {
            return false;
        }
        const substring = string.substring(stringLength - suffixLength);
        return substring === suffix;
    }

    isAlpha(string) {
        if (!string) {
            return false;
        }
        for (let i = 0; i < string.length; i++) {
            const char = string[i];
            if ((char < 'a' || char > 'z') && (char < 'A' || char > 'Z')) {
                return false;
            }
        }
        return true;
    }

    isNumeric(char) {
        return char >= '0' && char <= '9';
    }

    isWhitespace(char) {
        return char === " " || char === "\t" || char === "\r";
    }

   getNextToken() {
        let curr_char = this.current_char();
        let curr_line = this.line;
        let curr_pos = this.position_trace;
        let curr_look_ahead = this.current_look_ahead();
        if (this.isWhitespace(curr_char)) {
            this.next();
            return true;
        }
        if (curr_char === "\n") {
            this.line++;
            this.position_trace = 0;
            this.next();
            return true;
        }
    
        if (curr_char === "'" || curr_char === "\"") {
            let str = curr_char;
            this.next(); // open quote
            let count_dot = 0;
            while (this.current_char() !== curr_char) {
                if (this.current_char() === "\\" && this.current_look_ahead() === curr_char) {
                    str += this.current_char();
                    this.next();
                }
                str += this.current_char();
                this.next();
                if (this.char_pos > this.text.length) {
                    this.make_error("SyntaxError: unterminated string literal, missing closing", curr_char);
                    return false;
                }
            }
            str += curr_char;
            this.next(); // closing quote
            this.tokens.push(new BE_Token(BE_Token_TYPE.STRING, str, curr_line, curr_pos));
            return true;
        }
    
        if (this.isNumeric(curr_char)) {
            let number = "";
            let count_dot = 0;
            while (this.current_char() === "." || this.isNumeric(this.current_char())) {
                if (this.current_char() === ".") {
                    count_dot += 1;
                }
                number += this.current_char();
                this.next();
            }
            if (this.endsWith(number, ".") === true) {
                this.make_error("Invalid Number", number);
                return false;
            }
            if (count_dot > 1) {
                this.make_error("Invalid Number", number);
                return false;
            }
            this.tokens.push(new BE_Token(BE_Token_TYPE.NUMBER, number, curr_line, curr_pos));
            return true;
        }
    
        if (this.isAlpha(curr_char)) {
            let str = "";
            while (this.current_char() === "_" || this.isNumeric(this.current_char()) || this.isAlpha(this.current_char())) {
                str += this.current_char();
                this.next();
            }
            switch (str) {
                case "foreach":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.FOREACH, str, curr_line, curr_pos));
                    return true;
                case "end":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.END, str, curr_line, curr_pos));
                    return true;
                case "each":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.EACH, str, curr_line, curr_pos));
                    return true;
                case "in":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.IN, str, curr_line, curr_pos));
                    return true;
                case "loop":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.LOOP, str, curr_line, curr_pos));
                    return true;
                case "times":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.LOOP_TIMES, str, curr_line, curr_pos));
                    return true;
                case "if":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.IF, str, curr_line, curr_pos));
                    return true;
                case "else":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.ELSE, str, curr_line, curr_pos));
                    return true;
                case "function":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.FUNCTION, str, curr_line, curr_pos));
                    return true;
                case "and":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.AND, str, curr_line, curr_pos));
                    return true;
                case "or":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.OR, str, curr_line, curr_pos));
                    return true;
                case "not":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.NOT, str, curr_line, curr_pos));
                    return true;
                case "true":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.TRUE, str, curr_line, curr_pos));
                    return true;
                case "false":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.FALSE, str, curr_line, curr_pos));
                    return true;
                case "null":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.NULL, str, curr_line, curr_pos));
                    return true;
                case "skip":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.CONTINUES, str, curr_line, curr_pos));
                    return true;
                case "break":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.BREAK, str, curr_line, curr_pos));
                    return true;
                case "global":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.GLOBAL, str, curr_line, curr_pos));
                    return true;
                case "return":
                    this.tokens.push(new BE_Token(BE_Token_TYPE.RETURN, str, curr_line, curr_pos));
                    return true;
                default:
                    this.tokens.push(new BE_Token(BE_Token_TYPE.VARIABLE, str, curr_line, curr_pos));
                    return true;
            }
        }
    
        switch (curr_char) {
            case "+":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.PLUS, curr_char, curr_line, curr_pos));
                return true;
            case "-":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.MINUS, curr_char, curr_line, curr_pos));
                return true;
            case "*":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.MULTIPY, curr_char, curr_line, curr_pos));
                return true;
            case "/":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.DIVIDE, curr_char, curr_line, curr_pos));
                return true;
            case "%":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.MOD, curr_char, curr_line, curr_pos));
                return true;
            case "(":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.L_PAR, curr_char, curr_line, curr_pos));
                return true;
            case ")":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.R_PAR, curr_char, curr_line, curr_pos));
                return true;
            case "[":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.L_SQR, curr_char, curr_line, curr_pos));
                return true;
            case "]":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.R_SQR, curr_char, curr_line, curr_pos));
                return true;
            case ".":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.DOT, curr_char, curr_line, curr_pos));
                return true;
            case ",":
                this.next();
                this.tokens.push(new BE_Token(BE_Token_TYPE.COMMA, curr_char, curr_line, curr_pos));
                return true;
        }
    
        if (curr_char === "=" && curr_look_ahead !== "=") {
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.EQUAL, "=", curr_line, curr_pos));
            return true;
        }
        if (curr_char === "=" && curr_look_ahead === "=") {
            this.next();
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.EQUAL_EQAUL, "==", curr_line, curr_pos));
            return true;
        }
        if (curr_char === "!" && curr_look_ahead === "=") {
            this.next();
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.NOT_EQUAL, "!=", curr_line, curr_pos));
            return true;
        }
        if (curr_char === "<" && curr_look_ahead !== "=") {
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.LESS_THAN, "<", curr_line, curr_pos));
            return true;
        }
        if (curr_char === "<" && curr_look_ahead === "=") {
            this.next();
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.LESS_THAN_EQUAL, "<=", curr_line, curr_pos));
            return true;
        }
        if (curr_char === ">" && curr_look_ahead !== "=") {
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.GREATER_THAN, ">", curr_line, curr_pos));
            return true;
        }
        if (curr_char === ">" && curr_look_ahead === "=") {
            this.next();
            this.next();
            this.tokens.push(new BE_Token(BE_Token_TYPE.GREATER_THAN_EQUAL, ">=", curr_line, curr_pos));
            return true;
        }
        this.make_error("Invalid character " , this.char);
       return false
    }

    getTokens() {
      
        while (this.current_char() !== null) {
            const state = this.getNextToken();
            console.log(state)
            if (!state) return [];
        }
        if (this.tokens.length === 0) {
            this.tokens.push(new BE_Token(BE_Token_TYPE.EOF, "End Of File", this.line, this.position_trace));
        } else {
            const curr = this.tokens[this.tokens.length - 1];
            this.tokens.push(new BE_Token(BE_Token_TYPE.EOF, "End Of File", curr.line, curr.position + 1));
        }
        return this.tokens;
    }
}