import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';
import { MyValidator } from '../comp/MyValidator';
import TreeFlowService from '../ServicesLessCode/TreeFlowService';

export default function () {
    // var { flowData, setFlowData, complexFlowDialogs, setComplexFlowDialogs, projectID } = useContext(DB)
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs, projectID } = useContext(DB)

    var [variable_type, _] = useState([
        { "key": "Yes", "value": "yes" },
        { "key": "No", "value": "no" },
    ])

    var [treeflowData, setTreeflowData] = useState({})
    var [Inputs, setInputs] = useState([])
    var [treeFlowName, setTreeFlowName] = useState([])
    var [thisTreeVariableInput, setThishisTreeVariableInput] = useState([])
    var treeFlowService = new TreeFlowService()

    // flow_id

    useEffect(() => {


        treeFlowService.get_all_tree_flow(projectID).then(res => {
            var data = res.data

            var name_list = []
            data = data.map(e => {
                var vars = JSON.parse(e.flow_data)
                var is_route = parseInt(e.is_route) === 1 ? true : false
                if (flowData[conditionHash]?.name === e.name) {
                    setInputs(vars.input.concat(vars.variable).map(e => {
                        return { key: e.key }
                    }))
                }
                //if is a route dont show it
                if (!is_route) {
                    name_list.push({ name: e.name, id: e.id, variable: vars.input.concat(vars.variable) })
                }
                return e
            })

            setTreeFlowName(name_list)
        })
    }, [projectID, conditionHash])

    return (
        <Dialog header={<h4> {flowData[conditionHash]?.title ? flowData[conditionHash]?.title : " Flow Editor"}</h4>} style={{ "width": "100%", maxWidth: "600px" }} visible={complexFlowDialogs?.flowEditor} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, flowEditor: false })}>
            <div>
                <div className="col-12">
                    <label>Select Editor</label>
                    <Dropdown className='w-full' onChange={e => {
                        flowData[conditionHash].flow_inputs = {};
                        flowData.input.map((e, index) => {
                            flowData[conditionHash].flow_inputs[e.key] = { id: index, key: e.key, canAssign: "no", value: "", }
                        })
                        flowData.variable.map((e, index) => {
                            flowData[conditionHash].flow_inputs[e.key] = { id: index, key: e.key, canAssign: "no", value: "" }
                        })


                        setInputs(e.value.variable.map(e => {
                            return { key: e.key }
                        }))
                        // console.log( e.value)
                        flowData[conditionHash].flow_data = e.value;
                        flowData[conditionHash].title = `Flow Editor: ${e.value.name}`;
                        flowData[conditionHash].name = `${e.value.name}`;
                        flowData[conditionHash].flow_id = e.value.id;

                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash]?.flow_data} optionLabel='name'
                        options={treeFlowName} />
                </div>


                {Object.keys(flowData[conditionHash]?.flow_data || [])?.length > 0 && <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, flowEditor: false })
                    }} />
                </div>} size='small' value={Object.values(flowData[conditionHash]?.flow_inputs || [])} paginator rows={4} >

                    <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                    <Column header="Current Flow Vars" body={(data, row) =>
                        <div className='p-inputtext p-component p-filled w-full'>{data.key}</div>}></Column>

                    <Column header="Can Assign" body={(data, row) => <Dropdown className='w-full' onChange={e => {
                        console.log(flowData[conditionHash].flow_inputs[data.key])
                        try {
                            flowData[conditionHash].flow_inputs[data.key].canAssign = e.value;

                            if (e.value === "no") {
                                flowData[conditionHash].flow_inputs[data.key].value = null;
                            }
                            setFlowData({ ...flowData })
                        } catch (error) {

                        }
                    }} value={flowData[conditionHash].flow_inputs[data.key]?.canAssign} optionValue='value' optionLabel='key' options={variable_type} />}></Column>


                    <Column header={"Flow: " + flowData[conditionHash]?.title} body={(data, row) =>
                        <Dropdown className='w-full' onChange={e => {
                            console.log(flowData[conditionHash].flow_inputs)
                            console.log(data.key, e.value, flowData[conditionHash].flow_inputs)
                            try {
                                flowData[conditionHash].flow_inputs[data.key].value = e.value;
                            } catch (error) {

                            }
                            setFlowData({ ...flowData })
                        }}
                            disabled={flowData[conditionHash].flow_inputs[data.key]?.canAssign === "no"}
                            value={flowData[conditionHash].flow_inputs[data.key]?.value}
                            optionLabel='key'
                            optionValue='key'
                            options={Inputs} />
                    }></Column>

                    <Column header="" body={(data, row) => <i onClick={e => {

                        flowData[conditionHash].flow_inputs[data.key].value = null;
                        flowData[conditionHash].flow_inputs[data.key].canAssign = "no";
                        setFlowData({ ...flowData })
                    }} className="pi pi-trash add-button" style={{ fontSize: '1rem' }}></i>}></Column>

                </DataTable>}


            </div>
        </Dialog>
    )
}
