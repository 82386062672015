import React, { useEffect, useState } from 'react';
import TemplateComponent from 'react-mustache-template-component'
import swig from 'swig-templates'




const EmptyPage = () => {
    const [data, setData] = useState({});

    window.createList = function (listname, r) {
        data[listname] = r;
        setData({ ...data })
    }
    window.updateList = function (listname, r) {
        data[listname].push(r)
        setData({ ...data })
    }

    window.call = function (listname) {
        console.log(listname)
    }

    window.handleItemClick = function (val, key) {
        console.log(val,key)
        data[key] = data[key].filter(e =>e!=val)
        setData({ ...data })
    }
    var template = swig.compile(`
    <ul>
        {% for item in data.items %}
        <li   onclick="handleItemClick('{{ item }}','items')" >{{ item }}</li>
        {% endfor %}
    </ul>
    `);
    var output = template({
        pagename: 'awesome people',
        data: data
    });



    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <h5>Empty Page</h5>
                    <p>Use this page to start from scratch and place your custom content.</p>

                    <TemplateComponent template={output} data={{}} sanitize={false} />

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EmptyPage, comparisonFn);
