import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import ActionService from '../ServicesLessCode/ActionService';
import { DB } from '../App';
import { TabPanel, TabView } from 'primereact/tabview';
import DatabaseServices from '../ServicesLessCode/DatabaseServices';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
export default function ({ showVariable, setShowVariable }) {
    var { projectID, flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)
    var [variable, setVariable] = useState([])

    var [condition_type, _] = useState([
        { "key": "Is Empty", "value": "is_empty" },
        { "key": "Is Not Empty", "value": "is_not_empty" },

    ])

    var [condition, _] = useState([
        { "key": "notEqual", "value": "!=" },
        { "key": "equal", "value": "=" },
        { "key": "lessThanOrEqual", "value": "<=" },
        { "key": "lessThan", "value": "<" },
        { "key": "greaterThanOrEqual", "value": ">=" },
        { "key": "greaterThan", "value": ">" },
        { "key": "contains", "value": "LIKE" },
    ]);


    var [logicalCondition, _] = useState([
        { "key": "AND", "value": "AND" },
        { "key": "OR", "value": "OR" },
    ])


    var [selectorType, _] = useState([
        { "key": "INSERT", "value": "INSERT" },
        { "key": "DELETE", "value": "DELETE" },
        { "key": "SELECT", "value": "SELECT" },
        { "key": "UPDATE", "value": "UPDATE" },
    ])

    var [yesNoType, _] = useState([
        { "key": "Yes", "value": "yes" },
        { "key": "No", "value": "no" },
    ])

    var actionService = new ActionService()
    var database = new DatabaseServices()

    var [actionsList, setActionList] = useState([])
    useEffect(() => {
        // document.documentElement.style.fontSize = 12 + 'px';
        database.get_all_tables(projectID).then(res => {
            var data = []
            var res = res.data
            Object.keys(res).map(name => {
                var columns = res[name].map(e => {
                    var column_name = e.COLUMN_NAME
                    e = {}
                    e.name = column_name
                    e.hidden = false
                    e.active = false
                    e.isVariable = false
                    e.value = ""
                    return e;
                })
                data.push({ table_name: name, columns: columns })
            })
            setActionList(data)
        })
    }, [projectID, conditionHash])


    useEffect(() => {
        var variable = []
        flowData.input.forEach(e => {
            variable.push(e)
        })
        flowData.variable.forEach(e => {
            variable.push(e)
        })


        setVariable(variable)

    }, [projectID, conditionHash, flowData])


    function addAction() {
        flowData[conditionHash].table_where.push({ "key": "", "isVariable": "no", "value": "", "condition": "", "chain": "AND" })


        setFlowData({ ...flowData })

    }

    function removeAction(id) {
        flowData[conditionHash].table_where = flowData[conditionHash].table_where.filter((_, i) => i !== id)
        setFlowData({ ...flowData })
    }
    return (
        <Dialog header={<h6>Simple Query Builder</h6>} style={{ "width": "100%", maxWidth: "900px", minHeight: 400 }} visible={complexFlowDialogs?.query} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, query: false })}>
            <TabView>
                <TabPanel header="Selector">
                    <div>
                        {/* <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                            <h5>Condition</h5>
                        </div> */}


                        <label>Select Table</label>
                        <Dropdown className='w-full mb-5' onChange={e => {
                            var index = actionsList.findIndex(tab => tab.table_name === e.value)
                            if (index !== -1) {
                                var clean_hidden = actionsList[index].columns
                                flowData[conditionHash].query = clean_hidden;
                            }

                            flowData[conditionHash].table_name = e.value;
                            flowData[conditionHash].title = `Query: ${e.value}`;
                            if (e.value?.selector === "SELECT") {
                                flowData[conditionHash].IsSelect = true
                                flowData[conditionHash].select_row_limit = e.value.limit_rows;
                            } else {
                                flowData[conditionHash].IsSelect = false;
                                flowData[conditionHash].select_row_limit = "";

                            }
                            setFlowData({ ...flowData })
                        }} value={flowData[conditionHash]?.table_name} optionValue='table_name' optionLabel='table_name' options={actionsList} />

                        <label>Query Type</label>
                        <Dropdown className='w-full mb-5' onChange={e => {
                            flowData[conditionHash].selector = e.value;
                            if (e.value === "SELECT") {
                                flowData[conditionHash].IsSelect = true
                            } else {
                                flowData[conditionHash].IsSelect = false;
                                flowData[conditionHash].select_row_limit = "";

                            }
                            setFlowData({ ...flowData })
                        }} value={flowData[conditionHash]?.selector} optionValue='value' optionLabel='key' options={selectorType} />

                        {flowData[conditionHash]?.IsSelect && <>
                            <label>Set Row Limit</label>
                            <InputNumber className='w-full mb-5' onValueChange={e => {
                                flowData[conditionHash].select_row_limit = e.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash]?.select_row_limit} min={0} max={100000} />
                        </>}



                        {flowData[conditionHash]?.IsSelect && <>
                            <label>Success When?</label>
                            <Dropdown className='w-full mb-5' onChange={e => {
                                flowData[conditionHash].condition_type = e.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash]?.condition_type} optionLabel='key' optionValue='value' options={condition_type} />
                        </>}


                        <div className='flex align-items-center justify-content-between'>
                            <div className='w-full'>
                                <label>Store Output</label>
                                <Dropdown className='w-full mb-5' onChange={e => {
                                    flowData[conditionHash].results_variable = e.value;
                                    setFlowData({ ...flowData })
                                }} value={flowData[conditionHash]?.results_variable} optionLabel='key' options={variable} />
                            </div>
                            <i className="pi pi-trash delete-button ml-3" style={{ fontSize: '1rem' }}
                                onClick={e => {
                                    flowData[conditionHash].results_variable = "";
                                    setFlowData({ ...flowData })
                                }}
                            ></i>
                        </div>

                    </div>

                </TabPanel>
                {
                    (flowData[conditionHash]?.table_name?.length > 2 && ["INSERT", "UPDATE", "SELECT"].includes(flowData[conditionHash]?.selector)) && <TabPanel header="Hidden"    >
                        <div className="grid">
                            {
                                flowData[conditionHash]?.query?.map((column, index) => <div key={index} className="col-6">
                                    <Checkbox onChange={e => {
                                        var before = flowData[conditionHash].query[index].hidden
                                        flowData[conditionHash].query[index].hidden = !before
                                        setFlowData({ ...flowData })

                                    }} checked={column?.hidden}></Checkbox>
                                    <b style={{ marginLeft: 10 }}>
                                        {column?.name}
                                    </b>
                                </div>)
                            }

                        </div>
                    </TabPanel>
                }


                {
                    (flowData[conditionHash]?.table_name?.length > 2 && ["INSERT", "UPDATE"].includes(flowData[conditionHash]?.selector)) && <TabPanel header="Values"    >
                        <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                            <h5></h5>
                            {
                                (["INSERT"].includes(flowData[conditionHash]?.selector)) && <Button label='Save/Hide' onClick={e => {
                                    setComplexFlowDialogs({ ...complexFlowDialogs, query: false })
                                }} />
                            }

                        </div>} size='small' value={flowData[conditionHash]?.query?.filter(e => e.hidden === false)} paginator rows={4} >
                            <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                            <Column header="Column Name" body={(data, row) => <div className='p-inputtext p-component p-filled w-full'>
                                {data.name}
                            </div>}></Column>

                            <Column header="isVariable" body={(data, row) =>
                                <Checkbox onChange={e => {
                                    var before = flowData[conditionHash].query[row.rowIndex].isVariable === "yes" ? "no" : "yes"
                                    flowData[conditionHash].query[row.rowIndex].isVariable = before

                                    if (e.value === 'yes') {
                                        flowData[conditionHash].query[row.rowIndex].value = null
                                    } else {
                                        flowData[conditionHash].query[row.rowIndex].value = ''
                                    }
                                    setFlowData({ ...flowData })

                                }} checked={data.isVariable === "yes"}></Checkbox>
                            }></Column>

                            <Column header="Value" body={(data, row) => {
                                 var realIndex = flowData[conditionHash].query?.findIndex((e, i) => e.name === data.name)
                                return flowData[conditionHash].query[row.rowIndex].isVariable == "yes" ?
                                    <Dropdown style={{ width: "150px" }} onChange={e => {
                                        flowData[conditionHash].query[realIndex].value = e.value;
                                        setFlowData({ ...flowData })
                                    }} value={data.value} optionLabel='key' options={variable} />
                                    :
                                    <InputText style={{ width: "150px" }} onChange={e => {
                                        flowData[conditionHash].query[realIndex].value = e.target.value.trim();
                                        setFlowData({ ...flowData })

                                    }} value={data.value} />

                            }}></Column>
                        </DataTable>
                    </TabPanel>
                }


                {
                    (flowData[conditionHash]?.table_name?.length > 2 && ["UPDATE", "DELETE", "SELECT"].includes(flowData[conditionHash]?.selector)) && <TabPanel
                        header="Condition"    >
                        <div className="flex align-items-center justify-content-between px-2 mb-2 mt-2">
                            <Button label='Add Filter' onClick={e => {
                                addAction()
                            }} />
                        </div>

                        <DataTable

                            footer={<div className="card flex align-items-center justify-content-between ">
                                <h5></h5>
                                {
                                    (!["INSERT"].includes(flowData[conditionHash]?.selector)) && <Button label='Save/Hide' onClick={e => {
                                        setComplexFlowDialogs({ ...complexFlowDialogs, action: false })
                                    }} />
                                }

                            </div>}
                            size='small' value={flowData[conditionHash]?.table_where} paginator rows={10} >
                            <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                            <Column header="Key" body={(data, row) => <Dropdown className='w-full' onChange={e => {
                                flowData[conditionHash].table_where[row.rowIndex].key = e.value;
                                setFlowData({ ...flowData })
                            }} value={data.key} optionValue='name' optionLabel='name' options={flowData[conditionHash]?.query} />}></Column>


                            <Column header="Condition" body={(data, row) => <Dropdown style={{ width: "170px" }} onChange={e => {
                                flowData[conditionHash].table_where[row.rowIndex].condition = e.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash]?.table_where[row.rowIndex].condition} optionValue='value' optionLabel='key' options={condition} />}></Column>





                            <Column header="isVariable" body={(data, row) =>
                                <Checkbox onChange={e => {
                                    var before = flowData[conditionHash].table_where[row.rowIndex].isVariable === "yes" ? "no" : "yes"
                                    flowData[conditionHash].table_where[row.rowIndex].isVariable = before

                                    if (e.value === 'yes') {
                                        flowData[conditionHash].table_where[row.rowIndex].value = null
                                    } else {
                                        flowData[conditionHash].table_where[row.rowIndex].value = ''
                                    }
                                    setFlowData({ ...flowData })

                                }} checked={data.isVariable === "yes"}></Checkbox>
                            }></Column>


                            <Column header="Value" body={(data, row) => {
                                return flowData[conditionHash].table_where[row.rowIndex].isVariable == "yes" ?
                                    <Dropdown style={{ width: "150px" }} onChange={e => {
                                        flowData[conditionHash].table_where[row.rowIndex].value = e.value;
                                        setFlowData({ ...flowData })
                                    }} value={data.value} optionLabel='key' options={variable} />
                                    :
                                    <InputText style={{ width: "150px" }} onChange={e => {
                                        flowData[conditionHash].table_where[row.rowIndex].value = e.target.value.trim();
                                        setFlowData({ ...flowData })

                                    }} value={data.value} />

                            }}></Column>

                            <Column header="Chain" body={(data, row) => {
                                var sta = (row.rowIndex === 0 && flowData[conditionHash].table_where.length <= 1) || (row.rowIndex === flowData[conditionHash].table_where.length - 1);

                                return (
                                    sta ? <div className='p-inputtext p-component p-filled' style={{ color: "transparent", width: "150px" }}>
                                        ##
                                    </div>

                                        : <Dropdown style={{ width: "150px" }} onChange={e => {
                                            flowData[conditionHash].table_where[row.rowIndex].chain = e.value;
                                            setFlowData({ ...flowData })
                                        }} value={flowData[conditionHash]?.table_where[row.rowIndex].chain} optionValue='value' optionLabel='key' options={logicalCondition} />
                                )

                            }

                            }></Column>



                            <Column header="" body={(data, row) => <i onClick={e => removeAction(row.rowIndex)} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>


                        </DataTable>
                    </TabPanel>
                }

            </TabView>
        </Dialog>
    )
}
