import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useState } from 'react'
import GroupingService from '../ServicesLessCode/GroupingService'
import errorHandler from '../comp/errorHandler'
import { DB } from '../App'
import { confirmPopup } from 'primereact/confirmpopup'

export default function ({ project_id, ComponetName = "", CreateButtonName = "", requestor, tableTarget = [], inputsTarget = [] }) {
    const { toast } = useContext(DB);
    var errors = errorHandler(toast);
    var [refresh, setRefresh] = useState(false)
    var [showCreateUpdate, setShowCreateUpdate] = useState(false)
    var [isUpdate, setIsUpdate] = useState(false)


    var raw = { project_id: project_id }
    inputsTarget.map(e => {
        raw[e.target] = "";
    });

    var [form, setForm] = useState(raw)
    var [itemList, setItemList] = useState([])
    var [loading, setLoading] = useState(false)


    function onCreateGroupHandler() {
        setLoading(true)
        if (isUpdate) {
            requestor.update(form).then(res => {
                setLoading(false)
                if (res.status === true) {
                    setRefresh(!refresh)
                    setShowCreateUpdate(false)
                    setForm(raw)

                    errors.success(res.message)
                } else {
                    errors.error(res.message)
                }
            }).catch(e => {
                setLoading(false)
                errors.technical();
            })
            return;
        }
        requestor.create(form).then(res => {
            setLoading(false)
            if (res.status === true) {
                setRefresh(!refresh)
                setShowCreateUpdate(false)
                setForm(raw)
                errors.success(res.message)
            } else {
                errors.error(res.message)
            }
        }).catch(e => {
            setLoading(false)
            errors.technical();
        })
    }

    function onDeleteHandler(data) {
        form.id = data.id;
        requestor.delete(form).then(res => {
            setLoading(false)
            if (res.status === true) {
                setRefresh(!refresh)
                setShowCreateUpdate(false)
                setForm(raw)
                errors.success(res.message)
            } else {
                errors.error(res.message)
            }
        }).catch(e => {
            setLoading(false)
            errors.technical();
        })
    }


    useEffect(() => {
        requestor.get_all(project_id).then(res => {
            setItemList(res.data)
        })
    }, [refresh])
    return (
        <>
            <DataTable header={<div className="flex align-items-center justify-content-between px-2 mb-2">
                <div>{ComponetName}</div>
                <Button label={`Create ${CreateButtonName}`} onClick={e => {
                    setForm(raw)
                    setShowCreateUpdate(true)
                    setIsUpdate(false)
                }} />
            </div>}
                value={itemList} size={"small"} >
                <Column header="#" body={(data, row) => row.rowIndex + 1} ></Column>
                {tableTarget.map((item, i) => <Column key={i} header={item.label} field={item.target} ></Column>)}
                <Column header="Actions" body={data => <>

                    <i onClick={e => {

                        setShowCreateUpdate(true)
                        setIsUpdate(true)
                        setForm(data)
                    }
                    } className="pi pi-pencil  edit-button" style={{ fontSize: '1rem' }}></i>



                    <i onClick={e => {
                        confirmPopup({
                            target: e.currentTarget,
                            message: 'Are you sure you want to delete ?',
                            icon: 'pi pi-exclamation-triangle',
                            reject: () => { },
                            accept: () => onDeleteHandler(data)
                        });
                    }} className="pi pi-trash ml-3  delete-button" style={{ fontSize: '1rem' }}></i>

                </>}></Column>


            </DataTable>

            <Dialog header={`${isUpdate ? "Update" : "Create"} ${CreateButtonName}`} visible={showCreateUpdate} style={{ width: "400px" }} onHide={e => setShowCreateUpdate(false)}>
                <div className="grid">

                    {inputsTarget.map((item, index) => <div className="col-12" key={index}>
                        <label>{item.label}</label>
                        <InputText className='w-full' value={form[item.target]} onChange={e => setForm({ ...form, [item.target]: e.target.value })} />
                    </div>)}
                    <div className="col-12">
                        <Button loading={loading} label={isUpdate ? 'update' : "create"} className='w-full' onClick={e => {
                            onCreateGroupHandler()
                        }} />
                    </div>
                </div>
            </Dialog>
        </>
    )
}
