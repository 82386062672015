import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';

export default function () {
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)
    var [json_type, _] = useState([
        { "key": "variable", "value": "variable" },
        { "key": "string", "value": "string" },
        { "key": "bool", "value": "bool" },
        { "key": "number", "value": "number" },
    ])


    var [methods, _] = useState([
        { "key": "POST", "value": "post" },
        { "key": "GET", "value": "get" },
    ])
    var [types, _] = useState([
        { "key": "PATH", "value": "PATH" },
        { "key": "NOT PATH", "value": "NOT_PATH" },
    ])

    var [variables, set_variables] = useState([])

    useEffect(() => {
        var vars = []
        flowData.variable.map(e => {
            vars.push({ "key": e.key })
        })
        flowData.input.map(e => {
            vars.push({ "key": e.key })
        })
        set_variables(vars)
    }, [flowData])


    function addInput() {
        flowData[conditionHash].paths.push({
            key: "",
            type: "NOT_PATH",
        })
        setFlowData({ ...flowData })
    }

    function removeInput(id) {
        flowData[conditionHash].paths = flowData[conditionHash].paths.filter((row, i) => i !== id)
        setFlowData({ ...flowData })
    }

    return (
        <Dialog header={<b><h6>Routing</h6></b>} style={{ "width": "100%", maxWidth: "500px" }} visible={complexFlowDialogs?.route} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, route: false })}>
            <div>
                {/* <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                    <h5></h5>
                    <Button label='Create' onClick={e => {
                        addInput();
                    }} />
                </div> */}

                <label>Select Type</label>
                <Dropdown className='w-full' onChange={e => {
                    flowData[conditionHash].method = e.value;
                    setFlowData({ ...flowData })
                }} value={flowData[conditionHash]?.method} optionValue='value' optionLabel='key'
                    options={methods} />



                <DataTable header={<>
                    <Button label='Create' onClick={e => {
                        addInput();
                    }} />
                    <Button   className='ml-2'  label='Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, route: false })
                    }} />
                </>} size='small' value={flowData[conditionHash]?.paths} paginator rows={10} >
                    <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                    <Column header="Key" body={(data, row) => <InputText className='w-full' onChange={e => {
                        flowData[conditionHash].paths[row.rowIndex].key = e.target.value.trim();
                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash].paths[row.rowIndex].key} />}></Column>

                    <Column header="Type" body={(data, row) =>
                        <>
                            <Dropdown className='w-full' onChange={e => {
                                flowData[conditionHash].paths[row.rowIndex].type = e.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash]?.paths[row.rowIndex].type} optionValue='value' optionLabel='key' options={types} />

                        </>

                    }></Column>


                    <Column header="" body={(data, row) => <i onClick={e => { removeInput(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>

                </DataTable>
            </div>
        </Dialog>
    )
}
