import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react'
import RoutesTab from './RoutesTab';
import CodeGenTab from './CodeGenTab';
import ActionTab from './ActionTab';
import SmartTab from './SmartTab';
import DatabaseTab from './Screen/DatabaseTab';
import { Menubar } from 'primereact/menubar';
import ComplexFlowTab from './ComplexFlowTab';
import { InputText } from 'primereact/inputtext';
import GroupingService from '../ServicesLessCode/GroupingService';
import FolderServices from '../ServicesLessCode/FolderServices';
import SecretKeyServices from '../ServicesLessCode/SecretKeyServices';
import RequestBulder from './RequestBuilder';

export default function ({ selectedProject, setSelectedProject }) {
    var [selectedGroup, setSelectedGroup] = useState("")

    useEffect(() => {
        if (selectedProject !== null) {
            setSelectedGroup("group")
        }
    }, [selectedProject])

    const nestedMenuitems = [
        {
            label: 'Configuration',
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Group',
                    icon: 'pi pi-fw pi-microsoft',
                    command: () => setSelectedGroup("group")
                },
                {
                    label: 'Secret Keys',
                    icon: 'pi pi-fw pi-key',
                    command: () => setSelectedGroup("secret-keys")
                }
            ]
        },
        {
            label: 'Storage',
            icon: 'pi pi-fw pi-server',

            items: [
                {
                    label: 'Database',
                    icon: 'pi pi-fw pi-database',
                    command: () => setSelectedGroup("database")
                },
                {
                    label: 'File System',
                    icon: 'pi pi-fw pi-file',
                    command: () => setSelectedGroup("folder")
                }
            ]
        },
        {
            label: 'Controls',
            icon: 'pi pi-fw pi-code',
            command: () => setSelectedGroup("action"),
        },
        {
            label: <b>Back</b>,
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
                setSelectedProject(null)
                setSelectedGroup("")
            }
        }
    ];

    const menubarEndTemplate = () => {
        return (
            <>
                {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="text" placeholder="Search" />
            </span> */}
            </>
        );
    };
    var groupingService = new GroupingService()
    var folderServices = new FolderServices()
    var secretKeyServices = new SecretKeyServices()
    return (
        <>
        {/* <RequestBulder/> */}
            {selectedProject !== null &&

                <>
                    <Menubar model={nestedMenuitems} end={menubarEndTemplate}></Menubar>

                    {selectedGroup === "group" && <TabView >
                        <TabPanel header="Grouping">
                            <SmartTab
                                requestor={groupingService}
                                project_id={selectedProject}
                                ComponetName='Groups'
                                CreateButtonName='Group'
                                tableTarget={[{ "label": "Group Name", "target": "group_name" }]}
                                inputsTarget={[{ "label": "GroupName", "target": "group_name" }]} />
                        </TabPanel>
                    </TabView>}

                    {selectedGroup === "database" && <TabView ><TabPanel header="Database" sytle={{ width: "100%" }}>
                        <DatabaseTab project_id={selectedProject} />
                    </TabPanel>  </TabView>}

                    {selectedGroup === "secret-keys" && <TabView >
                        <TabPanel header="Secret Keys" sytle={{ width: "100%" }}>
                            <SmartTab
                                requestor={secretKeyServices}
                                project_id={selectedProject}
                                ComponetName='Secret Keys'
                                CreateButtonName='Secret Keys'
                                tableTarget={[{ "label": "Key", "target": "secret_key" }, { "label": "Value", "target": "secret_value" }]}
                                inputsTarget={[{ "label": "Key", "target": "secret_key" }, { "label": "Value", "target": "secret_value" }]} />
                        </TabPanel>  </TabView>}

                    {selectedGroup === "folder" && <TabView >
                        <TabPanel header="Folder" sytle={{ width: "100%" }}>
                            <SmartTab
                                requestor={folderServices}
                                project_id={selectedProject}
                                ComponetName='Folders'
                                CreateButtonName='Folder'
                                tableTarget={[{ "label": "Folder Name", "target": "folder_name" }]}
                                inputsTarget={[{ "label": "Folder Name", "target": "folder_name" }]} />
                        </TabPanel>  </TabView>}

                    {selectedGroup === "action" && <TabView >
                        <TabPanel header="Tree Flow">
                            <ComplexFlowTab />
                        </TabPanel>
                    </TabView>}

















                </>

            }

        </>
    )
}
