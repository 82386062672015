
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/tree-flow"
export default function() {
    this.create_tree_flow = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-tree-flow`, data).then(res => res.data);
    }
    this.update_tree_flow = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-tree-flow`, data).then(res => res.data);
    }
    
    this.get_all_tree_flow = (projectID) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-tree-flow/${projectID}`).then(res => res.data);
    }
    this.get_all_tree_flow_name = (projectID) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-tree-flow-name/${projectID}`).then(res => res.data);
    }
}
