
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/project"
export default function ProjectsService() {
    this.create_project = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-project`, data).then(res => res.data);
    }
    this.get_all_project = () => {
        return axios.post(`${APP_IP}/${base_path}/get-all-project`).then(res => res.data);
    }
}
