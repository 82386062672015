
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/folders"
export default function () {
    this.create = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-folder`, data).then(res => res.data);
    }
    this.update = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-folder`, data).then(res => res.data);
    }
    this.delete = (data) => {
        return axios.post(`${APP_IP}/${base_path}/delete-folder`, data).then(res => res.data);
    }
    this.get_all = (id) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-folders/${id}`).then(res => res.data);
    }
}
