import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import ActionService from '../ServicesLessCode/ActionService';
import { DB } from '../App';
import { TabPanel, TabView } from 'primereact/tabview';

export default function () {
    var { projectID, flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)
    var [variable, setVariable] = useState([])

    var [condition_type, _] = useState([
        { "key": "Is Empty", "value": "is_empty" },
        { "key": "Is Not Empty", "value": "is_not_empty" },

    ])
    useEffect(() => {
        var variable = []
        flowData.variable.forEach(e => {
            variable.push(e)
        })
        setVariable(variable)

    }, [projectID, conditionHash, flowData])



    return (
        <Dialog header={<h6>Condition</h6>} style={{ "width": "100%", maxWidth: "500px" }} visible={complexFlowDialogs?.condition} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, condition: false })}>
            <label>Condition Name</label>
            <InputText className='w-full' onChange={e => {
                flowData[conditionHash].title = e.target.value.trim();
                setFlowData({ ...flowData })
            }} value={flowData[conditionHash]?.title} />




            <label>Select Variable</label>
            <Dropdown className='w-full mb-5' onChange={e => {
                flowData[conditionHash].variable = e.value;
                setFlowData({ ...flowData })
            }} value={flowData[conditionHash]?.variable} optionValue='key' optionLabel='key' options={variable} />

        </Dialog>
    )
}
