'use strict';

import React, { useContext, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ProjectsService from '../ServicesLessCode/ProjectsService';

import { DB } from '../App';
import * as peggy from "peggy";
import { Grammerss_var } from './GRAMMEA';
import ProjectMainPage from './ProjectMainPage';
import ProjectAppBuilder from './ProjectAppBuilder';
import RequestBulder from './RequestBuilder';

import { code } from './pro.js'

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-one_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import DatabaseTab from './Screen/DatabaseTab.jsx';
import ActionTab from './ActionTab.jsx';
import RoutesTab from './RoutesTab.jsx';
import ComplexFlowTab from './ComplexFlowTab.jsx';
// import MonacoEditor from 'react-monaco-editor';
// import uniter from  'uniter'


// var engine = require('php-parser');

// // initialize a new parser instance
// var parser = new engine({
//   // some options :
//   parser: {
//     extractDoc: true,
//     php7: true
//   },
//   ast: {
//     withPositions: true
//   }
// });

// // Retrieve the AST from the specified source
// var _eval = parser.parseEval('echo "Hello World";');



// var phpEngine = window.uniter.createEngine('PHP');
//     useEffect(() => {

//         phpEngine.getStdout().on('data', function (data) {
//             console.log(data);
//         });


//         phpEngine.execute(code).fail(function (error) {
//             console.log(error.toString());
//         });

//       return phpEngine
//     }, [code])

async function onChange(text) {
    
    var phpEngine = window.uniter.createEngine('PHP');
    console.log(phpEngine)
    await phpEngine.getStdout().on('data', function (data) {
        console.log(data);
    });

    await phpEngine.getStderr().on('data', function (data) {
        console.log(data);
    });
    await phpEngine.execute(text, "D.php").fail(function (error) {
        console.log(error.toString());
    });
}




export default function () {

    var [selectedProject, setSelectedProject] = useState(null)
    var [projectsList, setProjectsList] = useState([])
    var [project_name, setproject_name] = useState("")
    var [refresh, setRefresh] = useState(false)
    var [show_create_project, setShowCreate_Project] = useState(false)
    var { SetProjectID } = useContext(DB)



    var projectsService = new ProjectsService()

    function onCreateProject() { 
        projectsService.create_project({ project_name: project_name }).then(res => {
            console.log(res)
            setRefresh(!refresh)
            setShowCreate_Project(false)
        })
    }


    useEffect(() => {
        // SetProjectID("663e5a5734940")
        projectsService.get_all_project().then(res => {
            // console.log(res)
            setProjectsList(res.data)
        })
    }, [refresh, setRefresh, selectedProject])

    var [code, setcode] = useState("")


    // useEffect(() => {

    //     const inputElements = window.document.querySelectorAll('.ace_text-input');

    //     // Loop through each input element and attach an event listener
    //     inputElements.forEach(function (inputElement) {
    //         inputElement.addEventListener("input", async function (event) {
    //             // console.log('Input value changed:', event.target.value);
             

    //         });
    //     });
    // }, [])





   



    return (
        <div >
        
            {/* <RequestBulder/> */}
            {/* <ProjectAppBuilder/> */}
            {/* <DatabaseTab/> */}
            {/* <ActionTab /> */}
            {/* <RoutesTab/> */}
            {/* <ComplexFlowTab /> */}
            <ProjectMainPage selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
            
            {selectedProject === null && <DataTable header={
                <div className="flex align-items-center justify-content-between px-2 mb-2">
                    <div>Applications</div>
                    <Button label='Create App' onClick={e => setShowCreate_Project(true)} />
                </div>
            } value={projectsList} size={"small"} >
                <Column header="#" body={(data, row) => row.rowIndex + 1} ></Column>
                <Column header="Name" field='project_name' ></Column>
                <Column header="Project ID" field='project_id' ></Column>
                <Column header="Actions" body={data => <>
                    <Button className='mr-2' label='Open' onClick={e => {
                        setSelectedProject(data.project_id)
                        SetProjectID(data.project_id)
                    }} />
                </>}></Column>
            </DataTable>}

            <Dialog visible={show_create_project} style={{ width: "400px" }} onHide={e => setShowCreate_Project(false)}>
                <div className="grid">
                    <div className="col-12">
                        <b>Project Name</b>
                    </div>
                    <div className="col-12">
                        <InputText className='w-full' value={project_name} onChange={e => setproject_name(e.target.value)} />
                    </div>
                    <div className="col-12">
                        <Button label='Open' className='w-full' onClick={onCreateProject} />
                    </div>
                </div>
            </Dialog>



            {/* <ReavtFlow1/> */}
            {/* <ComplexFlowTab /> */}




        </div>
    );
}