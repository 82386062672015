
function validattor () {
     return {
          variable: (input) => /[a-zA-Z_]+$/i.test(input),
          path: (input) => /[-a-zA-Z_0-9]+$/i.test(input),
     }
}


export  var MyValidator= validattor()
