import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';
import FolderServices from '../ServicesLessCode/FolderServices';

export default function () {
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs,projectID } = useContext(DB)
    var [json_type, _] = useState([
        { "key": "variable", "value": "variable" },
        { "key": "string", "value": "string" },
        { "key": "bool", "value": "bool" },
        { "key": "number", "value": "number" },
    ])
    var [folderList, setFolderList] = useState([])

    var folderServices = new FolderServices()

    var [variables, set_variables] = useState([])
    useEffect(() => {
        folderServices.get_all(projectID).then(res => {
            setFolderList(res.data)
        })
    }, [conditionHash,projectID])


    return (
        <Dialog  header={  <h5>Store File</h5>} style={{ "width": "100%", maxWidth: "1000px" }} visible={complexFlowDialogs?.fileUploader} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, fileUploader: false })}>
            <div>
                <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, fileUploader: false })
                    }} />
                </div>} size='small' value={flowData[conditionHash]?.fileUploader} paginator rows={4} >

                    <Column header="Store" body={(data, row) => <div className='p-inputtext p-component p-filled w-full'>
                        {flowData[conditionHash].fileUploader[row.rowIndex].key}
                    </div>}></Column>

                    <Column header="Values" body={(data, row) => {

                        switch (data.type) {
                            case "input":
                                {
                                    return <Dropdown className='w-full' onChange={e => {
                                        flowData[conditionHash].fileUploader[row.rowIndex].value = e.value;
                                        setFlowData({ ...flowData })
                                    }} value={flowData[conditionHash]?.fileUploader[row.rowIndex].value} optionValue='key' optionLabel='key'
                                        options={flowData.input.filter(e => e.type === "file")} />
                                }
                            case "folder":
                                {
                                    return <Dropdown className='w-full' onChange={e => {
                                        flowData[conditionHash].fileUploader[row.rowIndex].value = e.value;
                                        setFlowData({ ...flowData })
                                    }} value={flowData[conditionHash]?.fileUploader[row.rowIndex].value} optionValue='folder_name' optionLabel='folder_name'
                                        options={folderList} />
                                }
                            default: {
                                return <Dropdown className='w-full' onChange={e => {
                                    flowData[conditionHash].fileUploader[row.rowIndex].value = e.value;
                                    setFlowData({ ...flowData })
                                }} value={flowData[conditionHash]?.fileUploader[row.rowIndex].value} optionValue='key' optionLabel='key'
                                    options={flowData.variable} />
                            }
                        }
                    }




                    }></Column>
                </DataTable>
            </div>
        </Dialog>
    )
}
