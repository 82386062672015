import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useRef, useState } from 'react'
import AceEditor from "react-ace";

import LuaVM from "lua.vm.js"
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-one_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import { DB } from '../App';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
export default function ({ }) {
    var l = new LuaVM.Lua.State();
    var { flowData, setFlowData, complexFlowDialogs, setComplexFlowDialogs, conditionHash, projectID } = useContext(DB)

    var [variable, setVariable] = useState([])
    useEffect(() => {
        var variable = []
        flowData.input.forEach(e => {
            variable.push({ key: e.key })
        })
        flowData.variable.forEach(e => {
            variable.push({ key: e.key })
        })


        setVariable(variable)

    }, [projectID, conditionHash, flowData])


    return (
        <Dialog
            header={"Code Editor"} style={{ "width": "100%", maxWidth: "500px" }} visible={complexFlowDialogs?.validateGoogleAuthToken}
            onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, validateGoogleAuthToken: false })}>
            <div className="grid">
                <div className="col-12">
                    <label>Token Assign</label>
                </div>
                <div className="col-12">
                    <Dropdown className='w-full' onChange={e => {
                        flowData[conditionHash].token = e.value;
                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash]?.token} optionLabel='key' optionValue='key' options={variable} />
                </div>

                <div className="col-12">
                    <label>Output</label>
                </div>
                <div className="col-12">
                    <Dropdown className='w-full' onChange={e => {
                        flowData[conditionHash].output = e.value;
                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash]?.output} optionLabel='key' optionValue='key' options={variable} />
                </div>

                <div className="col-12">
                    <Button
                        className='w-full'
                        onClick={e => setComplexFlowDialogs({ ...complexFlowDialogs, validateGoogleAuthToken: false })}
                        label='Close' />
                </div>
            </div>
        </Dialog>
    )
}
