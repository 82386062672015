
var APP_IP = "https://cwmpapi.co.za"


if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    // Running locally
    APP_IP = "http://localhost:2000";
} else {
    // Running in production
    //only running locally
    //IP = "https://cwmpipo.co.za";
    APP_IP = "";
}

export { APP_IP }