function catchType(val) {
    try {
        if (!isNaN(parseFloat(val))) {
            return val
        }

        return `'${val}'`
    } catch (error) {
        console.log(val)
        return `'${val}'`
    }
}


/**
 * Blueprint structure 
 {name: "",
    limit_rows: 1,
    selector:"",
    table_name: selectedTable?.table_name,
    data: selectedTable?.table_data,
    filters: []
}
 */



function select_filter(current_action) {
    var filters_gen = ``;
    current_action.filters.forEach(val => {
        if (val.expression === "yes") {
            if (val.chain === "NONE") {
                filters_gen += `->where("${val.key}","${val.condition}",BE_Helper::sql_two_check('${val.value}',$args,$extract))\n`
            }
            if (val.chain === "OR") {
                filters_gen += `->orWhere("${val.key}","${val.condition}",BE_Helper::sql_two_check('${val.value}',$args,$extract))\n`
            }
            if (val.chain === "AND") {
                filters_gen += `->where("${val.key}","${val.condition}",BE_Helper::sql_two_check('${val.value}',$args,$extract))\n`
            }
        } else {
            if (val.chain === "NONE") {
                filters_gen += `->where("${val.key}","${val.condition}",${catchType(val.value)})\n`
            }
            if (val.chain === "OR") {
                filters_gen += `->orWhere("${val.key}","${val.condition}",${catchType(val.value)})\n`
            }
            if (val.chain === "AND") {
                filters_gen += `->where("${val.key}","${val.condition}",${catchType(val.value)})\n`
            }
        }

    })
    return filters_gen
}
function insert_filter(current_action) {
    var filters_gen = `->params([\n`;
    current_action.filters.forEach(val => {
        if (val.expression === "yes") {
            filters_gen += `"${val.key}"=>BE_Helper::sql_two_check('${val.value}',$args,$extract),\n`
        } else {
            filters_gen += `"${val.key}"=>${catchType(val.value)},\n`
        }
    })
    filters_gen += "])\n"
    return filters_gen
}

export default function (current_action, projectID) {
    if (current_action === null) return;
    var allHidden = current_action?.data?.filter(res => res.active === "yes")
    var func_gen = `function ${current_action?.name}_${projectID}_${"action"}($args=[],$extract=[],$is_tree_flow=false){\n`

    if (current_action.selector === "SELECT") {
        var sql_gen = `\n$generated_code= db()\n->select('${current_action?.table_name}_${projectID}')\n`;
        var filters_gen = select_filter(current_action)
        var hidden_gen = ``;
        if (allHidden) {
            allHidden.forEach(val => {
                hidden_gen += `->hidden("${val.name}")\n`
            })
        }

        sql_gen += filters_gen;
        sql_gen += hidden_gen;

        if (parseInt(current_action?.limit_rows) > 100000) {
            sql_gen += "->all();\n"
        } else {
            sql_gen += `->limit(${current_action.limit_rows})\n`
            sql_gen += "->all();\n"
        }
        func_gen += sql_gen;
        func_gen += "return $generated_code;\n";
    }
    if (current_action.selector === "INSERT") {
        func_gen += "try {\n";
        var sql_gen = `\ndb()\n->insert('${current_action?.table_name}_${projectID}')\n`;
        var filters_gen = insert_filter(current_action)
        sql_gen += filters_gen;
        sql_gen += "->execute();\n"
        func_gen += sql_gen;
        func_gen += `return ["lastID"=>db()->lastInsertId(),"status"=>true];\n`;
        func_gen += "}catch (\Throwable $th) {\n";
        func_gen += `return  ["status"=>false];\n`;
        func_gen += "\n}\n";
    }
    func_gen += "\n}\n";
    return func_gen;

}

