import React, { useContext, useEffect, useState } from 'react'

import { PanZoom } from 'react-easy-panzoom'
import ComplexFlowBlockInput from './ComplexFlowBlockInput';
import ComplexFlowBlockVariables from './ComplexFlowBlockVariables';

import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';
import { DB } from '../App';
import ComplexFlowBlockScript from './ComplexFlowBlockScript';
import complexCodeGenerate from '../codeGen/ComplexFlowGen';
import { Message } from 'primereact/message';
import TreeFlowService from '../ServicesLessCode/TreeFlowService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import GroupingService from '../ServicesLessCode/GroupingService';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ComplexFlowBlockJson from './ComplexFlowBlockJson';
import ComplexFlowBlockInputValidation from './ComplexFlowBlockInputValidation';
import { MyValidator } from '../comp/MyValidator';
import ComplexFlowBlockFileUploader from './ComplexFlowBlockFileUploader';
import ComplexFlowBlockQuery from './ComplexFlowBlockQuery';
import ComplexFlowBlockCondition from './ComplexFlowBlockCondition';
import ComplexFlowBlockJWT from './ComplexFlowBlockJWT';
import ComplexFlowBlockFlowEditor from './ComplexFlowBlockFlowEditor';


// import 'react-sortable-tree/style.css';

// You can import the default tree with dnd context
// import SortableTree from 'react-sortable-tree';

import { isMobile } from 'react-device-detect';
import { InputSwitch } from 'primereact/inputswitch';
import '@nosferatu500/react-sortable-tree/style.css';
// import { SortableTreeWithoutDndContext as SortableTree } from '@nosferatu500/react-sortable-tree';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
// import SortableTree from '@nosferatu500/react-sortable-tree';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from 'react-dnd-html5-backend';
import ComplexFlowBlockRoute from './ComplexFlowBlockRoute';
import ComplexFlowBlockValidateGoogleAuthToken from './ComplexFlowBlockValidateGoogleAuthToken';
import BELexer from './Engine/BE_Lexer';
import CodeGenService from '../ServicesLessCode/CodeGenService';
const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints)
const dndBackend = isMobile ? TouchBackend : HTML5Backend





export default function () {


    var { flowData, setFlowData, setConditionHash, projectID, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)

    var defualt_tree_raw = [
        { title: 'Input', type: "input", children: [], className: " node  flow-input", hash: "input" },
        { title: 'Variable', type: "variable", className: " node  flow-variable", children: [], hash: "variable" },
    ]
    var treeFlowDialogData_raw = { "name": "", group: "", id: 0, isRoute: false }
    const [treeData, setTreeData] = useState(defualt_tree_raw);
    const [treeFlowDialogData, setTreeFlowDialogData] = useState(treeFlowDialogData_raw);



    var [complexError, setComplexError] = useState([])
    var [refresh, setRefresh] = useState(true)
    var [isUpdateComplex, setIsUpdateComplex] = useState(false)
    var [treeflowDialog, setTreeflowDialog] = useState(false)
    var [treeflowGroup, setTreeflowGroup] = useState({})
    var treeFlowService = new TreeFlowService()
    var groupingService = new GroupingService()
    var codeGen = new CodeGenService();
    var [groupingList, setGroupingList] = useState([])

    var data = {
        "Inputs": [
            { "name": "input", type: "input" },
            { "name": "Variable", type: "variable" }

        ],
        "Auth": [
            { "name": "Validate Google Token", type: "validateGoogleAuthToken" },
            { "name": "JWT", type: "jwt" },
        ],
        "Blocks": [
            { "name": "Route", type: "route" },
            { "name": "Query", type: "query" },
            { "name": "Scripting", type: "script" },
            { "name": "Condition", type: "condition" },
            { "name": "File Uploader", type: "fileUploader" },
            { "name": "Flow Editor", type: "flowEditor" },

        ]
        ,
        "Reponse": [
            { "name": "Json", type: "json" },
        ],
        "Validation": [
            { "name": "Input", type: "inputValidation" },

        ],
    }



    function canDrop({ node, nextParent, prevPath, nextPath }) {
        if (["variable", "input", "condition", "query", "inputValidation", "json", "script", "fileUploader", "flowEditor"].includes(nextParent?.type)) {
            return false;
        }
        var falseCheck = ["variable", "input", "false", "true"]

        for (let index = 0; index < falseCheck.length; index++) {
            const element = falseCheck[index];
            try {
                if (treeData[prevPath[0]].hash.includes(element)) return false
            } catch (error) {

            }
            try {
                if (treeData[nextPath[0]].hash.includes(element)) return false
            } catch (error) {

            }
            if (node?.hash === element) return false
        }


        if (node.type === "input") {
            return false;
        }
        if (node.type === "variable") {
            return false;
        }
        if (node.type === "route") {
            return false;
        }
        if (node.type === "true") {
            return false;
        }
        if (node.type === "false") {
            return false;
        }

        return true;
    }

    function propsGenerator(type, node) {
        if (node.type === "true" || node.type === "false") {
            return type.title
        }
        if (type === "jwt" || type === "flowEditor" || type === "json" || type === "query" || type === "condition" || type === "input" || type === "variable" || type === "script" || type === "inputValidation" || "fileUploader") {
            return <div className='flow-node-space'>
                {(type === "query" || type === "inputValidation" || type === "jwt" || type === "flowEditor" || type === "condition") && <span className='left'> {flowData[node.hash].title} </span>}
                {(type !== "query" && type !== "inputValidation" && type !== "jwt" && type !== "flowEditor" &&  type !== "condition") && <span className='left'> {node.title} </span>}

                <span className='right'>
                    <i onClick={e => {

                        if (type === "input") {
                            // setShowInput(true)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "variable") {
                            // setShowVariable(true)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "query") {
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "condition") {
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "script") {
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                            // setShowScript(true)
                        }
                        if (type === "json") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "inputValidation") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "fileUploader") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "jwt") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }

                        if (type === "flowEditor") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }
                        if (type === "route") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }

                        if (type === "validateGoogleAuthToken") {
                            // setShowJsonObject(true)
                            setConditionHash(node.hash)
                            setComplexFlowDialogs({ ...complexFlowDialogs, [type]: true })
                        }





                    }} className="pi pi-pencil mr-3 edit-button" style={{ fontSize: '1rem' }}></i>
                    {(type !== "variable" && type !== "input") && <i className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}
                        onClick={e => handleDeleteNode(node)}
                    ></i>}

                </span>
            </div>
        } else {
            return type.title
        }
    }

    function add_block(type) {
        var key = uuidv4()
        if (type === "condition") {
            flowData[key] = { title: "Condition", variable: "" }
            treeData.push({
                title: "Condition", type: "condition", className: " node  flow-action", hash: key,
                children: [{ title: 'Success', type: "true", className: " node  flow-success", children: [] },
                { title: 'Fail', type: "false", className: " node  flow-fail", children: [] }]
            })
        }

        if (type === "query") {
            flowData[key] = { title: "Query", table_name: "", table_where: [], IsSelect: false, select_row_limit: 0, query: [], condition_type: "", selector: "", results_variable: "" }
            treeData.push({
                title: "Query", type: "query", className: " node  flow-action", hash: key, children: [{ title: 'Success', type: "true", className: " node  flow-success", children: [] }, { title: 'Fail', type: "false", className: " node  flow-fail", children: [] }]
            })
        }

        if (type === "script") {
            flowData[key] = { title: "Script", code: "" }
            treeData.push({ title: 'Script', hash: key, type: "script", children: [], className: " node  flow-script" })
        }

        if (type === "json") {
            flowData[key] = { title: "Json", code: "", json_data: [], returnType: "", storeResult: "" }
            treeData.push({ title: 'Json', type: "json", className: " node  flow-variable", children: [], hash: key, })
        }



        if (type === "fileUploader") {
            flowData[key] = {
                title: "File Uploader", fileUploader: [
                    { key: "File Input", value: "", type: "input" },
                    { key: "Folder", value: "", type: "folder" },
                    { key: "filePath", value: "", type: "return" },
                    { key: "isSuccess?", value: "", type: "status" },
                ]
            }
            treeData.push({ title: 'File Uploader', type: "fileUploader", className: " node  flow-variable", children: [], hash: key, })
        }
        if (type === "jwt") {
            flowData[key] = {
                title: "JWT",
                option: "",
                jwt: [
                    { key: "Secret Key", field: "secret", value: "", type: "create|validate|refresh" },
                    { key: "Store Info", field: "store", value: "", type: "create" },
                    // { key: "Old Token", field: "old", value: "", type: "refresh" },
                    { key: "Expiry Time", field: "time", value: "", type: "create|validate|refresh" },

                    { key: "Store New Token", field: "token", value: "", type: "create|refresh" },
                    { key: "Store Results", field: "results", value: "", type: "validate" },
                    { key: "isValid?", field: "valid", value: "", type: "validate|refresh" },
                ]
            }


            treeData.push({ title: 'JWT', type: "jwt", className: " node  flow-variable", children: [], hash: key, })
        }



        if (type === "inputValidation") {
            flowData[key] = { title: "Input Validation", form: [], input: "" }
            treeData.push({ title: 'Input Validation', type: "inputValidation", children: [], className: " node  flow-script", hash: key })
        }


        if (type === "flowEditor") {
            flowData[key] = { title: "Flow Editor", name: "", flow_id: "", flow_data: {}, flow_inputs: {} }
            treeData.push({ title: 'Flow Editor', type: "flowEditor", className: " node  flow-variable", children: [], hash: key, })
        }

        if (type === "route") {
            ///check if thes route assined
            //and only 1 route allowed
            if (treeData.length > 0) {
                if (treeData[0].type === "route") {
                    alert("route exist, you cannot have two routes")
                    return;
                }
            }
            flowData[key] = { title: "Route", paths: [], method: "GET" }
            // push the route to the top first
            treeData.unshift({
                title: 'Route',
                type: 'route',
                className: 'node flow-variable',
                children: [],
                hash: key
            });
            setTreeFlowDialogData({ ...treeFlowDialogData, isRoute: true })
        }


        if (type === "validateGoogleAuthToken") {
            flowData[key] = { title: "Validate Google Token", token: "", output: {} }
            treeData.push({ title: 'Validate Google Token', type: "validateGoogleAuthToken", className: " node  flow-variable", children: [], hash: key, })
        }







        setFlowData({ ...flowData })
        setTreeData([...treeData])
    }




    var [lock, setLock] = useState(true)





    const handleDeleteNode = (node) => {
        const updatedTreeData = removeNodeAndChildren(treeData, node);
        setTreeData(updatedTreeData);
    };

    const removeNodeAndChildren = (tree, targetNode) => {
        return tree.filter(node => {
            if (node === targetNode) {
                //delete also the hash data that was linked to the nbode
                delete flowData[node.hash]
                setFlowData({ ...flowData })

                if (node.type === "route") {
                    //tell it that route is removed
                    setTreeFlowDialogData({ ...treeFlowDialogData, isRoute: false })
                }
                return false; // Exclude target node and its children
            }
            if (node.children) {
                node.children = removeNodeAndChildren(node.children, targetNode);
                return true;
            }
            return true;
        });
    };


    async function MycodeGen() {
        await codeGen.create_route({ project_id: projectID, code: "" }).then(res => {
            alert(res?.message)
        })
    }
    function onCreateTreeFlow() {
        var form = {
            "name": treeFlowDialogData.name,
            "is_route": treeFlowDialogData.isRoute,
            "group_name": treeFlowDialogData.group,
            "project_id": projectID,
            "flow_data": JSON.stringify(flowData),
            "tree_data": JSON.stringify(treeData)
        }

        if (isUpdateComplex) {
            form["id"] = treeFlowDialogData.id


            treeFlowService.update_tree_flow(form).then(async (res) => {
                await MycodeGen()
                await setRefresh(!refresh)
                await setTreeflowDialog(false)
            })
            return;
        }

        treeFlowService.create_tree_flow(form).then(async (res) => {
            await MycodeGen()
            await setRefresh(!refresh)
            await setTreeflowDialog(false)
        })
    }


    useEffect(() => {
        groupingService.get_all(projectID).then(res => {
            setGroupingList(res.data)
        })
        treeFlowService.get_all_tree_flow(projectID).then(res => {
            var data = res.data
            data = data.map(e => {
                e.tree_data = JSON.parse(e.tree_data)
                e.flow_data = JSON.parse(e.flow_data)
                return e
            })
            var group = {}
            data.forEach(e => {
                if (e.group_name in group) {
                    group[e.group_name].push(e)
                } else {
                    group[e.group_name] = [e]
                }
            })
            setTreeflowGroup(group)
        })
    }, [projectID, refresh])

    return (
        <>

            <div className="card flex align-items-center justify-content-between px-2 mb-2">
                <div><b>Tree Flow</b></div>
                <Button label='Create' onClick={e => {
                    setTreeFlowDialogData(treeFlowDialogData_raw)
                    setFlowData({
                        input: [],
                        variable: []
                    })
                    setTreeData(defualt_tree_raw)
                    setTreeflowDialog(true)
                    setIsUpdateComplex(false)
                }} />
            </div>


            <Accordion activeIndex={0}>
                {Object?.keys(treeflowGroup).map((key, id) => {
                    var listing = treeflowGroup[key];
                    return <AccordionTab className='mb-2' header={key} key={id}>

                        <div className="grid my-2">
                            {listing?.map((li, index) => <div key={index} className="col-12 md:col-6 lg:col-4">
                                <div className="card border-1 p-4">
                                    <div className="flex align-items-center justify-content-between px-1">
                                        <span>{li?.name}</span>
                                        <div>
                                            <i onClick={e => {

                                                var is_route = parseInt(li.is_route) === 1 ? true : false
                                                setFlowData(li.flow_data)
                                                setTreeData(li.tree_data)
                                                setTreeflowDialog(true)
                                                setIsUpdateComplex(true)
                                                setTreeFlowDialogData({ name: li.name, group: li.group_name, id: li.id, isRoute: is_route })

                                            }} className="pi pi-pencil mr-3 add-button" style={{ fontSize: '1rem' }}></i>
                                            <i onClick={e => {
                                                // setShowAction(true)
                                                // setAction(li)
                                            }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>
                                        </div>
                                    </div>
                                    {/* {route_builder(li).map(e=>e)} */}
                                </div>
                            </div>)}
                        </div>
                    </AccordionTab>
                })}
            </Accordion>

            <ComplexFlowBlockCondition />
            <ComplexFlowBlockQuery />
            <ComplexFlowBlockJson />
            <ComplexFlowBlockRoute />
            <ComplexFlowBlockValidateGoogleAuthToken />
            <ComplexFlowBlockInput />
            <ComplexFlowBlockVariables />
            <ComplexFlowBlockScript />
            <ComplexFlowBlockInputValidation />
            <ComplexFlowBlockFileUploader />
            <ComplexFlowBlockJWT />
            <ComplexFlowBlockFlowEditor />

            <Dialog header={
                <div className="flex align-items-center justify-content-between ">
                    <span>
                        Flow Editor - <small> <b>{treeFlowDialogData.isRoute ? <span style={{ color: "#30c9dc" }}>Route</span> : <span style={{ color: "#fbc02d" }}>Service</span>}</b></small>
                        {/* <InputSwitch  checked={lock} onChange={(e) => setLock(e.value)} /> */}
                    </span>
                    <Button onClick={onCreateTreeFlow} label={isUpdateComplex ? "Update" : "Create"} />

                </div>

            }
                maximized style={{ "width": "100%", height: "100vh !important" }} visible={treeflowDialog} onHide={e => setTreeflowDialog(false)}>

                <div className="flow-scene">
                    <div className="editor"   >
                        <PanZoom
                            boundaryRatioVertical={0.9}
                            boundaryRatioHorizontal={0.9}
                            enableBoundingBox
                            autoCenterZoomLevel={1} disableDoubleClickZoom={true} disableScrollZoom={lock} disabled={lock} disableKeyInteraction={lock}>




                            <DndProvider backend={dndBackend}>
                                <SortableTree

                                    canDrop={canDrop}
                                    isVirtualized={false}
                                    treeData={treeData}
                                    generateNodeProps={({ node, path }) => {
                                        return {
                                            className: `${node.className}`,
                                            title: propsGenerator(node.type, node)
                                        };
                                    }}
                                    onChange={newTreeData => setTreeData(newTreeData)}
                                />

                            </DndProvider >

                        </PanZoom>
                        {complexError.map((e, i) => <Message key={i} className='w-full my-2' severity="error" text={e} />)}
                    </div>


                    <div className="drop-box"  >
                        <div className="grid">
                            <div className="col-12">
                                <div className="heading">Tree Flow Name</div>
                            </div>
                            <div className="col-12">
                                <InputText value={treeFlowDialogData.name} onChange={e => {
                                    if (e.target.value.trim() === "" || MyValidator.variable(e.target.value.trim())) {
                                        setTreeFlowDialogData({ ...treeFlowDialogData, name: e.target.value.trim() })
                                    }

                                }

                                } />
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="heading">Group</div>
                            </div>
                            <div className="col-12">
                                <Dropdown optionValue='group_name' optionLabel='group_name' value={treeFlowDialogData?.group} onChange={e => {
                                    setTreeFlowDialogData({ ...treeFlowDialogData, group: e.value })
                                }} className='w-full' options={groupingList} />
                            </div>
                        </div>
                        {Object.keys(data).map((name, id) => <div key={id}>
                            <div className="col-12">
                                <div className="heading">{name}</div>
                            </div>
                            <div className="grid">
                                {data[name].map((row, index) =>
                                    <div className="col-6" key={index}>
                                        <div className={`type  ${(row.type === "input" || row.type === "variable" || (treeData.filter(e => e.type === "form").length > 0 && row.type === "form")) && "cant-click"}`} onClick={e => {
                                            if (!(row.type === "input" || row.type === "variable" || (treeData.filter(e => e.type === "form").length > 0 && row.type === "form"))) {
                                                add_block(row.type)
                                            }
                                        }
                                        }>
                                            {row.name}
                                        </div>
                                    </div>
                                )} </div>
                        </div>)}

                    </div>
                </div>
            </Dialog>


        </>
    )
}
