
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/group"
export default function () {
    this.create = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-group`, data).then(res => res.data);
    }
    this.update = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-group`, data).then(res => res.data);
    }
    this.delete = (data) => {
        return axios.post(`${APP_IP}/${base_path}/delete-group`, data).then(res => res.data);
    }
    this.get_all = (projectID) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-groups/${projectID}`).then(res => res.data);
    }
}
