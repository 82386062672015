import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useState } from 'react'
import { DB } from '../App';
import { MyValidator } from '../comp/MyValidator';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';


export default function ({ }) {

    var { flowData, setFlowData, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)

    var [json_type, _] = useState([
        { "key": "String", "value": "string" },
        { "key": "Array", "Array": "string" },
        { "key": "Bool", "value": "bool" },
        { "key": "Number", "value": "number" },
        { "key": "Null", "value": "null" },
    ])
    function addVariable() {
        flowData.variable.push({ key: "", share: false, group: "null", var_value: 'null' })
        setFlowData({ ...flowData })
    }

    function removeVariable(id) {
        flowData.variable = flowData.variable.filter((row, i) => i !== id)
        setFlowData({ ...flowData })
    }

    return (
        <Dialog showHeader={false} style={{ "width": "100%", maxWidth: "800px" }} visible={complexFlowDialogs?.variable} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, variable: false })}>
            <div>
                <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                    <h5>Request Variable</h5>
                    <Button label='Create' onClick={e => {
                        addVariable()
                    }} />
                </div>
                <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        // setShowVariable(false)
                        setComplexFlowDialogs({ ...complexFlowDialogs, variable: false })

                    }} />
                </div>} size='small' value={flowData?.variable} paginator rows={4} >
                    <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                    <Column header="Variable Name" body={(data, row) => <InputText className='w-full' onChange={e => {
                        flowData.variable[row.rowIndex].key = e.target.value.trim();
                        if (e.target.value.trim() === "" || MyValidator.variable(e.target.value.trim())) {
                            setFlowData({ ...flowData })
                        }
                    }} value={data.key} />}></Column>

                    <Column header="Type" body={(data, row) => <Dropdown className='w-full' onChange={e => {
                        flowData.variable[row.rowIndex].group = e.value;
                        if (e.value === 'null') {
                            flowData.variable[row.rowIndex].value = "null"
                        }
                        setFlowData({ ...flowData })
                    }} value={data.group} optionValue='value' optionLabel='key' options={json_type} />}></Column>

                    <Column header="Value" body={(data, row) => <InputText disabled={data.group === "null"} className='w-full' onChange={e => {
                        flowData.variable[row.rowIndex].var_value = e.target.value
                        setFlowData({ ...flowData })

                    }} value={data.var_value} />}></Column>
                    <Column header="Share" body={(data, row) => <Checkbox onChange={e => {
                        flowData.variable[row.rowIndex].share = e.checked
                        setFlowData({ ...flowData })

                    }} checked={data?.share}></Checkbox>}></Column>
                    <Column header="" body={(data, row) => <i onClick={e => { removeVariable(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>

                </DataTable>
            </div>
        </Dialog>
    )
}
