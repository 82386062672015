import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';


export default function () {
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs, projectID } = useContext(DB)
    var [json_type, _] = useState([
        { "key": "Validate", "value": "validate" },
        { "key": "Create", "value": "create" },
        { "key": "Refresh", "value": "refresh" },
    ])



    function filter_array(fil = "") {
        var ar = []
        var jwt_data = flowData[conditionHash]?.jwt;
        jwt_data.forEach(data => {
            if (data.type.includes(fil)) {
                console.log(data, fil)
                ar.push(data)
            }
        });
        console.log(ar)
        return ar
    }

    function updateFilter(field_name, value) {
        console.log(field_name, value)
        flowData[conditionHash].jwt= flowData[conditionHash]?.jwt.map(e => {
            if (e.field === field_name) {
                e.value = value;
            }
            return e;
        })
        setFlowData({ ...flowData })
    }

    function getFilterValue(field_name) {
        var res=flowData[conditionHash]?.jwt.filter(e => e.field === field_name)
        if(res.length>0) return res[0].value;
        else return ""
    }
    function getFilterName(field_name) {
        var res=flowData[conditionHash]?.jwt.filter(e => e.field === field_name)
        if(res.length>0) return res[0].key;
        else return ""
    }


    return (
        <Dialog header={<h5>JWT</h5>} style={{ "width": "100%", maxWidth: "1000px" }} visible={complexFlowDialogs?.jwt} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, jwt: false })}>
            <div>
                <label>Select Type</label>
                <Dropdown className='w-full' onChange={e => {
                    flowData[conditionHash].option = e.value;
                    flowData[conditionHash].title=`${ e.value} JWT`
                    setFlowData({ ...flowData })

                }} value={flowData[conditionHash]?.option} optionValue='value' optionLabel='key'
                    options={json_type} />

                {flowData[conditionHash]?.option?.trim()?.length > 2 && <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, jwt: false })
                    }} />
                </div>} size='small' value={filter_array(flowData[conditionHash]?.option)}  >

                    <Column header="Store" body={(data, row) => <div className='p-inputtext p-component p-filled w-full'>
                        {getFilterName(data.field)}
                    </div>}></Column>

                    <Column header="Values" body={(data, row) => {

                        switch (data.field) {
                            case "time":
                                {
                                    return <InputText className='w-full' onChange={e => {
                                        updateFilter(data.field, e.target.value.trim())


                                    }} value={getFilterValue(data.field)} />
                                }
                            case "secret":
                                {
                                    return <InputText className='w-full' onChange={e => {
                                        updateFilter(data.field, e.target.value.trim())


                                    }} value={getFilterValue(data.field)} />
                                }
                            default: {
                                return <Dropdown className='w-full' onChange={e => {
                                    updateFilter(data.field, e.value)

                                }} value={getFilterValue(data.field)} optionValue='key' optionLabel='key'
                                    options={flowData?.variable} />
                            }
                        }
                    }




                    }></Column>
                </DataTable>}

            </div>
        </Dialog>
    )
}
