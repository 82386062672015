
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/code-gen"
export default function() {
    this.create_route = (data) => {
        return axios.post(`${APP_IP}/${base_path}/gen`, data).then(res => res.data);
    }
    this.update_route = (data) => {
        return axios.post(`${APP_IP}/${base_path}/append-file`, data).then(res => res.data);
    }
}
