import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useRef, useState } from 'react'
import AceEditor from "react-ace";

import LuaVM from "lua.vm.js"
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/theme-one_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import { DB } from '../App';
import { Message } from 'primereact/message';
export default function ({ }) {
    var l = new LuaVM.Lua.State();
    var { flowData, setFlowData, complexFlowDialogs, setComplexFlowDialogs, conditionHash } = useContext(DB)

    var [mycode, setMyCode] = useState("")
    var [myerror, setMyError] = useState("")
   

    useEffect(() => {
        setMyCode(flowData[conditionHash]?.code)
    }, [conditionHash])

    function onChange(text) {
        setMyCode(text)
        console.log(flowData[conditionHash].code)
        flowData[conditionHash].code = text;
        setFlowData({ ...flowData })
    }

    useEffect(() => {
        try {
            setMyError("")
            l.load(mycode===undefined?"":mycode)
            
        } catch (error) {
            // console.log(error)
            setMyError(`${error}`.split("]:")[1])
        }
    }, [mycode])
    

    return (
        <Dialog footer={myerror?.trim().length>2&&<Message  className='w-full my-2' severity="error" text={myerror} /> }
        
        header={"Code Editor"} style={{ "width": "100%", maxWidth: "1000px" }} visible={complexFlowDialogs?.script}
            onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, script: false })}>
            <AceEditor
                editor={(e) => {
                    console.log(e)
                }}
                mode="lua"
                theme="one_dark"
                value={mycode}
                onChange={onChange}
                name="UNIQUE_ID_OF_DIV"
                setOptions={{ fontSize: 16 }}
                editorProps={{ $blockScrolling: true }}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                style={{ width: "100%", height: "100vh" }}

            />
        </Dialog>
    )
}
