import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/database"
export default function DatabaseServices() {
    this.get_all_database_types = () => {
        return axios.post(`${APP_IP}/${base_path}/get-all-database-types`).then(res => res.data);
    }
    this.get_all_database_keys = () => {
        return axios.post(`${APP_IP}/${base_path}/get-all-database-keys`).then(res => res.data);
    }

    this.get_all_tables = (project_id) => {
        return axios.post(`${APP_IP}/${base_path}/get-all-tables`, { "project_id": project_id }).then(res => res.data);
    }

    this.create_table = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-table`, data).then(res => res.data);
    }
    this.update_table = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-table`, data).then(res => res.data);
    }
    this.delete_table = (data) => {
        return axios.post(`${APP_IP}/${base_path}/delete-table`, data).then(res => res.data);
    }



    this.create_column = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-column`, data).then(res => res.data);
    }
    this.update_column = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-column`, data).then(res => res.data);
    }
    this.delete_column = (data) => {
        return axios.post(`${APP_IP}/${base_path}/delete-column`, data).then(res => res.data);
    }


}
