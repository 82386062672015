import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import DatabaseServices from '../../ServicesLessCode/DatabaseServices';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DB } from '../../App';
import ErrorHelper from '../../comp/ErrorHelper';
import { confirmPopup } from 'primereact/confirmpopup';
import { Dropdown } from 'primereact/dropdown';
export default function DatabaseTab() {
    var { toast, projectID } = useContext(DB)
    var errors = ErrorHelper(toast)
    var databaseServices = new DatabaseServices()
    var [items, setItems] = useState([])
    var [refresh, setRefresh] = useState(false)
    var [loading, setLoading] = useState(false)
    var raw_table_name_data = { isUpdate: false, show_table_name: false, table_name: "" }
    var [tableName, setTableName] = useState(raw_table_name_data)
    var [tableData, setTableData] = useState({
        show: false,
    })


    var yes_no = [
        { "key": "YES", "value": true },
        { "key": "NO", "value": false },
    ]

    var data_types = [
        { "key": "INT", "value": "INT" },
        { "key": "VARCHAR", "value": "VARCHAR" },
        // { "key": "SMALL", "value": "TEXT" },
        { "key": "TEXT", "value": "SMALL" },
    ]


    var raw_column_data = {
        COLUMN_NAME: "",
        DATA_TYPE: "",
        DATA_TYPE_LENGTH: 100,
        IS_PRIMARY: false,
        OLD_COLUMN_NAME: ""
    }

    var [columnData, setColumnData] = useState({
        show: false,
        isUpdate: false,
        data: raw_column_data
    })


    useEffect(() => {
        if (projectID !== null) {
            databaseServices.get_all_tables(projectID).then(res => {
                var data = res.data;
                var store = []
                Object.keys(data).forEach(key => {
                    var row = data[key]
                    store.push({
                        TABLE_NAME: key,
                        TABLE_DATA: row
                    })

                    //in case of delete or update
                    if (tableData.table_name === key) {
                        setTableData({ ...tableData, TABLE_DATA: row })
                    }

                })
                setItems(store)
            })
        }

    }, [refresh, setRefresh, projectID])


    function create_update_table_handler() {
        tableName.project_id = projectID;
        setLoading(true)
        if (tableName.isUpdate === false) {
            databaseServices.create_table(tableName).then(res => {
                console.log(res)
                if (res.status === true) {
                    errors.success(res.message)
                    setRefresh(!refresh)
                    setTableName({ ...tableName, show_table_name: false })
                } else {
                    errors.error(res.message)
                }
                setLoading(false)
            })
        } else {


            var update_table = {
                "project_id": projectID,
                "table_name": tableName.old_table_name,
                "new_table_name": tableName.table_name
            }

            databaseServices.update_table(update_table).then(res => {
                if (res.status === true) {
                    errors.success(res.message)
                    setRefresh(!refresh)
                    setTableName({ ...tableName, show_table_name: false })
                } else {
                    errors.error(res.message)
                }
                setLoading(false)
            })
        }

    }

    function delete_table_handler(e, table_name) {
        tableName.project_id = projectID;
        tableName.table_name = table_name;

        confirmPopup({
            target: e.currentTarget,
            message: <b>Are you sure you want to delete table -- <span style={{ color: "red" }}>{table_name}</span></b>,
            icon: 'pi pi-exclamation-triangle',
            accept: function () {
                setLoading(true)
                databaseServices.delete_table(tableName).then(res => {
                    if (res.status === true) {
                        errors.success(res.message)
                        setRefresh(!refresh)
                        setTableName({ ...tableName, show_table_name: false })
                    } else {
                        errors.error(res.message)
                    }
                    setLoading(false)
                })
            },
            reject: () => { }
        });

    }



    function create_update_column_handler() {
        tableName.project_id = projectID;
        var data = {
            "project_id": projectID,
            "table_name": tableData.table_name,
            "column_name": columnData.data.COLUMN_NAME,
            "column_type": columnData.data.DATA_TYPE,
            "column_size": (columnData.data.DATA_TYPE_LENGTH === null || columnData.data.DATA_TYPE_LENGTH.length === 0) ? 100 : columnData.data.DATA_TYPE_LENGTH,
            "primary_key": columnData.data.IS_PRIMARY
        }
        setLoading(true)
        if (columnData.isUpdate === false) {
            databaseServices.create_column(data).then(res => {
                console.log(res)
                if (res.status === true) {
                    errors.success(res.message)
                    setRefresh(!refresh)
                    setColumnData({ ...columnData, show: false })
                } else {
                    errors.error(res.message)
                }
                setLoading(false)
            })
        } else {

            data.column_name = columnData.data.OLD_COLUMN_NAME
            data.new_column_name = columnData.data.COLUMN_NAME

            databaseServices.update_column(data).then(res => {
                if (res.status === true) {
                    errors.success(res.message)
                    setRefresh(!refresh)
                    setColumnData({ ...columnData, show: false })
                } else {
                    errors.error(res.message)
                }
                setLoading(false)
            })
        }

    }
    function delete_column_handler(e, table_name, column_name) {
        tableName.project_id = projectID;
        tableName.table_name = table_name;
        var data = {
            "project_id": projectID,
            "table_name": table_name,
            "column_name": column_name
        }



        confirmPopup({
            target: e.currentTarget,
            message: <b>Are you sure you want to delete column -- <span style={{ color: "red" }}>{column_name}</span></b>,
            icon: 'pi pi-exclamation-triangle',
            accept: function () {
                setLoading(true)
                databaseServices.delete_column(data).then(res => {
                    if (res.status === true) {
                        errors.success(res.message)
                        setRefresh(!refresh)

                        // setTableName({ ...tableName, show_table_name: false })
                    } else {
                        errors.error(res.message)
                    }
                    setLoading(false)
                })
            },
            reject: () => { }
        });

    }
    return (
        <>
            <DataTable header={
                <div className="flex align-items-center justify-content-between px-2 mb-2">
                    <div>Tables</div>
                    <Button label='Create' onClick={e => {
                        setTableName({ ...tableName, isUpdate: false, show_table_name: true, table_name: "" })
                    }} />
                </div>
            } value={items} size={"small"} >
                <Column header="#" body={(_, row) => <b>{row.rowIndex + 1}</b>} ></Column>
                <Column header="Name" body={data => <b>{data.TABLE_NAME}</b>} ></Column>
                <Column header="Total Row" body={data => <b>{data?.TABLE_DATA?.length}</b>} ></Column>
                <Column header="Actions" body={data =>
                    <>
                        <i className="pi pi-eye  add-button mr-4" style={{ fontSize: '1rem' }} onClick={e => setTableData({ ...tableData, show: true, table_name: data.TABLE_NAME, TABLE_DATA: data.TABLE_DATA })} ></i>
                        <i className="pi pi-pencil  edit-button mr-4"
                            onClick={() => setTableName({ ...tableName, isUpdate: true, show_table_name: true, table_name: data.TABLE_NAME, old_table_name: data.TABLE_NAME })}
                            style={{ fontSize: '1rem' }}></i>
                        <i className="pi pi-trash  delete-button" onClick={e => delete_table_handler(e, data.TABLE_NAME)} style={{ fontSize: '1rem' }}></i>
                    </>
                } ></Column >
            </DataTable >

            <Dialog header={`${tableName.isUpdate ? "Update" : "Create"} Table`} visible={tableName.show_table_name} onHide={e => setTableName(raw_table_name_data)}
                style={{ maxWidth: 500, width: "100%" }}>
                <div className="grid">
                    <div className="col-12">
                        <InputText className='w-full' onChange={e => setTableName({ ...tableName, table_name: e.target.value })} value={tableName.table_name} />
                    </div>
                    <div className="col-12">
                        <Button loading={loading} onClick={create_update_table_handler} className='w-full' label='Submit' />
                    </div>
                </div>
            </Dialog>

            <Dialog header={` Table(${tableData?.table_name?.toUpperCase()})`} visible={tableData.show} onHide={e => setTableData({ ...tableData, show: false })}
                style={{ maxWidth: 500, width: "100%" }}>
                <DataTable header={
                    <div className="flex align-items-center justify-content-between px-2 mb-2">
                        <div>Columns</div>
                        <Button label='Create' onClick={e => {
                            setColumnData({ ...columnData, show: true, isUpdate: false, data: raw_column_data })
                        }} />
                    </div>
                } value={tableData.TABLE_DATA} size={"small"} >
                    <Column header="#" body={(_, row) => <b>{row.rowIndex + 1}</b>} ></Column>
                    <Column header="NAME" body={data => <b>{data.COLUMN_NAME} <span style={{ color: "#50C878" }} >{data.IS_PRIMARY && "(Default)"}</span> </b>} ></Column>
                    <Column header="TYPE" body={data => <b>{data.DATA_TYPE}</b>} ></Column>
                    {/* <Column header="PRIMARY KEY" body={data => <b>{data.IS_PRIMARY ? "YES" : "NO"}</b>} ></Column> */}
                    {/* <Column header="LENGTH" body={data => <b>{data.DATA_TYPE_LENGTH}</b>} ></Column> */}
                    <Column header="ACTIONS" body={data =>
                        <>
                            <i className="pi pi-pencil  edit-button mr-4"
                                onClick={() => {
                                    data["OLD_COLUMN_NAME"] = data["COLUMN_NAME"];
                                    setColumnData({ ...columnData, show: true, isUpdate: true, data: data })

                                }}
                                style={{ fontSize: '1rem' }}></i>
                            <i className="pi pi-trash  delete-button" onClick={e => delete_column_handler(e, tableData.table_name, data.COLUMN_NAME)} style={{ fontSize: '1rem' }}></i>
                        </>
                    } ></Column >
                </DataTable >
            </Dialog>



            <Dialog header={`${columnData.isUpdate ? "Update" : "Create"} Column`} visible={columnData.show} onHide={e => setColumnData({ ...columnData, show: false })}
                style={{ maxWidth: 500, width: "100%" }}>
                <div className="grid">
                    <div className="col-12">
                        <label>Column Name</label>
                        <InputText className='w-full'
                            onChange={e => {
                                columnData.data.COLUMN_NAME = e.target.value
                                setColumnData({ ...columnData })
                            }}
                            value={columnData?.data?.COLUMN_NAME}
                        />
                    </div>
                    <div className="col-12">
                        <label>Column Type</label>
                        <Dropdown className='w-full' optionLabel='key' optionValue='value' options={data_types}
                            onChange={e => {
                                columnData.data.DATA_TYPE = e.value
                                setColumnData({ ...columnData })
                            }}
                            value={columnData?.data?.DATA_TYPE}
                        />
                    </div>
                    {/* <div className="col-12">
                        <label>Is Primary Key</label>
                        <Dropdown className='w-full' optionLabel='key' optionValue='value' options={yes_no}
                            onChange={e => {
                                columnData.data.IS_PRIMARY = e.value
                                setColumnData({ ...columnData })
                            }}
                            value={columnData?.data?.IS_PRIMARY}
                        />
                    </div> */}

                    <div className="col-12">
                        <label>Column Size</label>
                        <InputText className='w-full'
                            onChange={e => {
                                columnData.data.DATA_TYPE_LENGTH = e.target.value
                                setColumnData({ ...columnData })
                            }}
                            value={columnData?.data?.DATA_TYPE_LENGTH}
                        />
                    </div>

                    <div className="col-12">
                        <Button loading={loading} onClick={create_update_column_handler} className='w-full' label='Submit' />
                    </div>
                </div>
            </Dialog>

        </>
    )
}
