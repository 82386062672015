
import axios from 'axios';
import { APP_IP } from './Config';

var base_path = "v1/less-code/actions"
export default function() {
    this.create_actions = (data) => {
        return axios.post(`${APP_IP}/${base_path}/create-action`, data).then(res => res.data);
    }
    this.update_action = (data) => {
        return axios.post(`${APP_IP}/${base_path}/update-action`, data).then(res => res.data);
    }
    
    this.get_all_actions = (projectID) => {
        return axios.get(`${APP_IP}/${base_path}/get-all-actions/${projectID}`).then(res => res.data);
    }
}
