import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';

export default function () {
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)
    var [json_type, _] = useState([
        { "key": "variable", "value": "variable" },
        { "key": "string", "value": "string" },
        { "key": "bool", "value": "bool" },
        { "key": "number", "value": "number" },
    ])


    var [return_type, _] = useState([
        { "key": "Response", "value": "response" },
        { "key": "Storage", "value": "storage" },
        { "key": "FastAccessorStorage", "value": "fastAccessorStorage" },
        { "key": "FastAccessorResponse", "value": "fastAccessorResponse" },
    ])

    var [variables, set_variables] = useState([])

    useEffect(() => {
        var vars = []
        flowData.variable.map(e => {
            vars.push({ "key": e.key })
        })
        flowData.input.map(e => {
            vars.push({ "key": e.key })
        })
        set_variables(vars)
    }, [flowData])


    function addInput() {
        flowData[conditionHash].json_data.push({
            key: "",
            type: "",
            value: "",
        })
        setFlowData({ ...flowData })
    }

    function removeInput(id) {
        flowData[conditionHash].json_data = flowData[conditionHash].json_data.filter((row, i) => i !== id)
        setFlowData({ ...flowData })
    }

    return (
        <Dialog style={{ "width": "100%", maxWidth: "1000px" }} visible={complexFlowDialogs?.json} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, json: false })}>
            <div>
                <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                    <h5>Simple Json Object</h5>
                    <Button label='Create' onClick={e => {
                        addInput();
                    }} />
                </div>

                <label>Select Type</label>
                <Dropdown className='w-full' onChange={e => {
                    flowData[conditionHash].returnType = e.value;
                    setFlowData({ ...flowData })
                }} value={flowData[conditionHash]?.returnType} optionValue='value' optionLabel='key'
                    options={return_type} />

                {(["storage"].includes(flowData[conditionHash]?.returnType)) && <div>
                    <label className='mt-3'>Select Variable to Store the Json</label>
                    <Dropdown className='w-full mb-2' onChange={e => {
                        flowData[conditionHash].storeResult = e.value;
                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash]?.storeResult} optionValue='key' optionLabel='key'
                        options={flowData?.variable} />

                </div>}


                {(["response", "storage"].includes(flowData[conditionHash]?.returnType)) &&
                    <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                        <h5></h5>
                        <Button label='Save/Hide' onClick={e => {
                            setComplexFlowDialogs({ ...complexFlowDialogs, json: false })
                        }} />
                    </div>} size='small' value={flowData[conditionHash]?.json_data} paginator rows={4} >
                        <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                        <Column header="Key" body={(data, row) => <InputText className='w-full' onChange={e => {
                            flowData[conditionHash].json_data[row.rowIndex].key = e.target.value;
                            setFlowData({ ...flowData })
                        }} value={flowData[conditionHash].json_data[row.rowIndex].key} />}></Column>


                        <Column header="Type" body={(data, row) => <Dropdown className='w-full' onChange={e => {
                            flowData[conditionHash].json_data[row.rowIndex].type = e.value;
                            setFlowData({ ...flowData })
                        }} value={flowData[conditionHash]?.json_data[row.rowIndex].type} optionValue='value' optionLabel='key' options={json_type} />}></Column>

                        <Column header="Input Name" body={(data, row) =>
                            <>
                                {flowData[conditionHash].json_data[row.rowIndex].type === "variable" && <Dropdown className='w-full' onChange={e => {
                                    flowData[conditionHash].json_data[row.rowIndex].value = e.value;
                                    setFlowData({ ...flowData })
                                }} value={flowData[conditionHash]?.json_data[row.rowIndex].value} optionValue='key' optionLabel='key' options={variables} />}

                                {flowData[conditionHash].json_data[row.rowIndex].type !== "variable" && <InputText className='w-full' onChange={e => {
                                    flowData[conditionHash].json_data[row.rowIndex].value = e.target.value;
                                    setFlowData({ ...flowData })
                                }} value={flowData[conditionHash].json_data[row.rowIndex].value} />}
                            </>

                        }></Column>


                        <Column header="" body={(data, row) => <i onClick={e => { removeInput(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>

                    </DataTable>}



                {
                    (["fastAccessorResponse", "fastAccessorStorage"].includes(flowData[conditionHash]?.returnType)) && <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                        <h5></h5>
                        <Button label='Save/Hide' onClick={e => {
                            setComplexFlowDialogs({ ...complexFlowDialogs, json: false })
                        }} />
                    </div>} size='small' value={flowData[conditionHash]?.json_data} paginator rows={4} >
                        <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                        <Column header="Key" body={(data, row) => <InputText className='w-full' onChange={e => {
                            flowData[conditionHash].json_data[row.rowIndex].key = e.target.value;
                            setFlowData({ ...flowData })
                        }} value={flowData[conditionHash].json_data[row.rowIndex].key} />}></Column>

                        <Column header="Access Variable" body={(data, row) =>
                            <Dropdown className='w-full' onChange={e => {
                                flowData[conditionHash].json_data[row.rowIndex].type = e.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash]?.json_data[row.rowIndex].type} optionValue='key' optionLabel='key' options={variables} />
                        }></Column>

                        <Column header="Accessor Input" body={(data, row) =>
                            <InputText className='w-full' onChange={e => {
                                flowData[conditionHash].json_data[row.rowIndex].value = e.target.value;
                                setFlowData({ ...flowData })
                            }} value={flowData[conditionHash].json_data[row.rowIndex].value} />

                        }></Column>
                        <Column header="" body={(data, row) => <i onClick={e => { removeInput(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>
                    </DataTable>
                }



            </div>
        </Dialog>
    )
}
