import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { DB } from '../App';

export default function () {
    var { flowData, setFlowData, conditionHash, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)

    var [any_type, _] = useState([
        { "key": "is A Number", "value": "is_number", isInput: false },
        { "key": "Remove Space Start & End", "value": "remove_space_start_end", isInput: false },
        { "key": "Remove All Space", "value": "remove_all_space", isInput: false },
        { "key": "is String", "value": "is_string", isInput: false },
        { "key": "Is Decimal", "value": "is_decimal", isInput: false },
        { "key": "Is Bool", "value": "is_bool", isInput: false },
        { "key": "Is Empty", "value": "is_empty", isInput: false },
        { "key": "Is Not Empty", "value": "is_not_empty", isInput: false },
        { "key": "Max Length", "value": "max_length", isInput: true },
        { "key": "Min Length", "value": "min_length", isInput: true },
        { "key": "Start With", "value": "start_with", isInput: true },
        { "key": "end With", "value": "end_with", isInput: true },
        { "key": "Contain", "value": "contain", isInput: true },
    ])

    var [file_type, _] = useState([
        { "key": "File Size", "value": "file_size", isInput: true },
        { "key": "File Type", "value": "start_with", isInput: true },
    ])





    var [inputs, setInputs] = useState([])

    useEffect(() => {
        var vars = []
        flowData.input.map(e => {
            vars.push({ key: e.key })
        })
        flowData.variable.map(e => {
            vars.push({ key: e.key })
        })
        setInputs(vars)
    }, [flowData])


    function addInput() {
        console.log(flowData)
        flowData[conditionHash].form.push({
            key: "",
            isInput: false,
            value: "",
        })
        setFlowData({ ...flowData })
    }

    function removeInput(id) {
        flowData[conditionHash].form = flowData[conditionHash].form.filter((row, i) => i !== id)
        setFlowData({ ...flowData })
    }

    return (
        <Dialog style={{ "width": "100%", maxWidth: "1000px" }} visible={complexFlowDialogs?.inputValidation} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, inputValidation: false })}>
            <div>
                <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                    <h5>Input Validation</h5>
                    <Button label='Create' onClick={e => {
                        addInput();
                    }} />
                </div>

                <Dropdown className='w-full' onChange={e => {
                    flowData[conditionHash].input = e.value;
                    console.log( e.value)
                    flowData[conditionHash].title = `${e.value} Validation`;
                    setFlowData({ ...flowData })
                }} value={flowData[conditionHash]?.input} optionLabel='key' optionValue='key' options={inputs} />


                <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, inputValidation: false })
                    }} />
                </div>} size='small' value={flowData[conditionHash]?.form} paginator rows={4} >
                    <Column header="#" body={(_, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                    <Column header="Type" body={(_, row) => <Dropdown className='w-full' onChange={e => {
                        //if the type is file
                        if (flowData[conditionHash]?.input?.type === "file") {
                            e.value = file_type.filter(a => a.value === e.value)[0]
                        } else {
                            e.value = any_type.filter(a => a.value === e.value)[0]
                        }

                        flowData[conditionHash].form[row.rowIndex].type = e.value.value
                        flowData[conditionHash].form[row.rowIndex].isInput = e.value.isInput;
                        if (e.value.isInput === false) {
                            flowData[conditionHash].form[row.rowIndex].value = "Check";

                        } else {
                            flowData[conditionHash].form[row.rowIndex].value = "";
                        }
                        setFlowData({ ...flowData })
                    }} value={flowData[conditionHash]?.form[row.rowIndex].type} optionLabel='key'
                        options={flowData[conditionHash]?.input?.type === "file" ? file_type : any_type} />}></Column>

                    <Column header="Input" body={(_, row) =>
                        <InputText className='w-full'
                            disabled={flowData[conditionHash].form[row.rowIndex].type === "" || flowData[conditionHash].form[row.rowIndex].isInput === false}
                            onChange={e => {
                                if (flowData[conditionHash].form[row.rowIndex].isInput === true) {
                                    flowData[conditionHash].form[row.rowIndex].value = e.target.value;
                                    setFlowData({ ...flowData })
                                }

                            }} value={flowData[conditionHash].form[row.rowIndex].value} />}></Column>





                    <Column header="" body={(_, row) => <i onClick={e => { removeInput(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>

                </DataTable>
            </div>
        </Dialog>
    )
}
