import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useState } from 'react'
import { DB } from '../App';
import { MyValidator } from '../comp/MyValidator';
import { Checkbox } from 'primereact/checkbox';

export default function () {
    var { flowData, setFlowData, complexFlowDialogs, setComplexFlowDialogs } = useContext(DB)
    var [variable_type, _] = useState([
        { "key": "Query", "value": "query" },
        { "key": "Path", "value": "path" },
        { "key": "Post", "value": "post" },
        { "key": "Get", "value": "get" },
        { "key": "File", "value": "file" },
        { "key": "Header", "value": "header" },
    ])
    function addInput() {
        flowData.input.push({
            key: "",
            type: "",
            value:""
        })
        setFlowData({ ...flowData })
    }

    function removeInput(id) {
        flowData.input = flowData.input.filter((row, i) => i !== id)
        setFlowData({ ...flowData })
    }

    return (
        <Dialog showHeader={false} style={{ "width": "100%", maxWidth: "500px" }} visible={complexFlowDialogs?.input} onHide={e => setComplexFlowDialogs({ ...complexFlowDialogs, input: false })}>
            <div>
                <div className="card flex align-items-center justify-content-between px-2 mb-2 mt-2">
                    <h5>Request Input</h5>
                    <Button label='Create' onClick={e => {
                        addInput();
                    }} />
                </div>
                <DataTable footer={<div className="card flex align-items-center justify-content-between ">
                    <h5></h5>
                    <Button label='Save/Hide' onClick={e => {
                        setComplexFlowDialogs({ ...complexFlowDialogs, input: false })
                    }} />
                </div>} size='small' value={flowData?.input} paginator rows={4} >
                    <Column header="#" body={(data, row) => <h5>{row.rowIndex + 1}</h5>}></Column>

                    <Column header="Input Name" body={(data, row) => <InputText className='w-full' onChange={e => {
                        flowData.input[row.rowIndex].key = e.target.value.trim();
                        if (e.target.value.trim() === "" || MyValidator.variable(e.target.value.trim())) {
                            setFlowData({ ...flowData })
                        }
                    }} value={data.key} />}></Column>

                    <Column header="Type" body={(data, row) => <Dropdown className='w-full' onChange={e => {
                        flowData.input[row.rowIndex].type = e.value;
                        if (e.value === "header") {
                            flowData.input[row.rowIndex].value = "";
                        }
                        setFlowData({ ...flowData })
                    }} value={data.type} optionValue='value' optionLabel='key' options={variable_type} />}></Column>

                    <Column header="Header Name" body={(data, row) => <InputText disabled={data.type !== "header"} className='w-full' onChange={e => {
                        flowData.input[row.rowIndex].value = e.target.value.trim();
                        setFlowData({ ...flowData })
                    }} value={data.value} />}></Column>

                    <Column header="" body={(data, row) => <i onClick={e => { removeInput(row.rowIndex) }} className="pi pi-trash delete-button" style={{ fontSize: '1rem' }}></i>}></Column>

                </DataTable>
            </div>
        </Dialog>
    )
}
